<template>
  <div>
    <!--당첨-->

      <v-card
          elevation="24"
      >
        <v-simple-table
            style="background-color: rgba(245, 241, 238, 0.08)"
        >
          <tbody
              v-for="(betItem,i) in myBetList"
              :key="i">
          <tr class="game-info">
            <td colspan="4" class="text-left">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  <div class="pr-2">
                    [{{ betItem.gameCodeText }}]
                  </div>
                  <div>
                    {{ betItem.league }}
                  </div>

                </div>
                <div>
                  <div>
                    {{ betItem.matchedAt }}
                    <v-btn
                        tile
                        color="black"
                        small
                        class=""
                        @click="cancelBetItem(betItem.betItemId)"
                        :disabled="(betItem.status !== 'READY' || betItem.status === 'DENY') || (menuCode === 'VSOCCER' || menuCode === 'VBASKETBALL')"
                    >관리자취소
                    </v-btn>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr class="game-info">
            <td class="text-center "
                :class="betItem.whichBet === 'HOME' ? 'betSelected font-weight-bold': ''"
                style="width:39%">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  {{
                    betItem.special === '' || betItem.special === null ? betItem.teamHome : betItem.teamHome + ' [' + betItem.special + ']'
                  }}
                </div>
                <div class="d-flex align-center">
                  {{ betItem.oddsHome }}
                </div>
              </div>
            </td>
            <td class="text-center"
                :class="betItem.whichBet === 'DRAW' ? 'betSelected font-weight-bold': ''"
            >
              <div class="d-flex align-center">
                {{ betItem.oddsDraw === 0 ? 'vs' : betItem.oddsDraw }}
              </div>
            </td>
            <td class="text-center"
                :class="betItem.whichBet === 'AWAY' ? 'betSelected font-weight-bold': ''"
                style="width:39%"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  {{ betItem.oddsAway }}
                </div>
                <div class="d-flex align-center">
                  {{
                    betItem.special === '' || betItem.special === null ? betItem.teamAway : '[' + betItem.special + '] ' + betItem.teamAway
                  }}
                </div>
              </div>
            </td>
            <td class="text-center" style="width:20%">
              <div v-if="betItem.scoreHome !== null && betItem.scoreHome !== null">
                {{ betItem.scoreHome }} : {{ betItem.scoreAway }}
              </div>
              <div v-else>
                {{ betItem.statusValue }}
              </div>
              <div>
                <v-icon color="success" small v-if="betItem.status==='WIN'">mdi-circle-outline</v-icon>
                <v-icon color="error" small v-else-if="betItem.status==='LOSE'">mdi-close</v-icon>
                <v-icon color="white" small v-else-if="betItem.status==='DENY' || betItem.status.includes('CANCEL')">mdi-cancel</v-icon>
                <v-icon color="white" small v-else-if="betItem.status==='READY'">mdi-timer-sand</v-icon>
                <v-icon color="white" small v-else-if="betItem.status==='CALCULATING'">mdi-calculator</v-icon>
                <v-icon color="primary" small v-else-if="betItem.status==='INVALID'">mdi-triangle-outline</v-icon>
              </div>
            </td>

          </tr>

          </tbody>
        </v-simple-table>
      </v-card>
      <v-divider
          class="mt-4"
          dark
      ></v-divider>

    </div>


</template>

<script>
export default {
  name: "SportsMobile",
  props: {
    myBetList: {
      type: Array
    },
    menuCode: {
      type: String
    }

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },


  }
}
</script>

<style scoped>

</style>
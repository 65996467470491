import Vue from 'vue'

export const eventBus = new Vue({
    // 메소드로 만들어 형제 컴포넌트에서 선언한 userWasEdited를 메소드화
    methods:{
        updateTopBarData() {
            this.$emit('updateTopBarData');
        },
        getData() {
            this.$emit('getData');
        }
    }
})

<template>
  <v-snackbar
      :value="dialog"
      light
      color="black"
      elevation="24"
      vertical
      :timeout="timeout"
      class="mx-auto"
      content-class="mx-auto"
      @input="closeSnackBar"
  >
    <div class="font-weight-bold white--text">{{ text }}</div>

    <template v-slot:action="{ attrs }">
      <v-btn
          color="red"
          text
          class="font-weight-bold"
          v-bind="attrs"
          @click="closeSnackBar"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  data: () => ({
    timeout: 2000
  }),
  props: {
    dialog: {
      type: Boolean,
    },
    text: {
      type: String
    }

  },
  methods: {

    closeSnackBar: function () {
      this.$emit('update:dialog', !this.dialog);
    }
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router";
import store from "./store";
import './plugins/vee-validate'
import {setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate';
import AlertDialog from '@/components/_Default/AlertDialog'
import ConfirmDialog from '@/components/_Default/ConfirmDialog'
import SnackBar from '@/components/_Default/SnackBar'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import SubMenu from '@/components/_Default/SubMenu'
import NumberToKorFilter from 'vue-number-to-kor';
import globalMethod from './plugins/global'
import './plugins/filters'
import "./plugins/vuetify-money.js";
import BetDialog from "@/components/_Default/BetDialog";
import DetailBetDialog from "@/components/_Default/DetailBetDialog";
import VueNumber from "vue-number-animation";

Vue.component('alert-dialog', AlertDialog);
Vue.component('confirm-dialog', ConfirmDialog);
Vue.component('bet-dialog', BetDialog);
Vue.component('detail-bet-dialog', DetailBetDialog);
Vue.component('snack-bar', SnackBar);

Vue.component('sub-menu', SubMenu);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);



setInteractionMode('aggressive')
Vue.config.productionTip = false
Vue.use(PerfectScrollbar)
Vue.filter("makeComma", val =>{
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})


Vue.use(NumberToKorFilter);
Vue.use(globalMethod)
Vue.use(VueNumber);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')




<template>

  <v-card
      elevation="2"
      min-width=""
      outlined
      class="mx-auto w-100 statusBoardCard"
  >
    <v-card-text class="pa-0">
      <div
          class="position-relative">
        <v-overlay

            :value="overlay"
            opacity="0.8"
        >
          <div class="text-center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </div>
          <div>
            데이터 로딩 중입니다. <br/>
            잠시만 기다려주세요.
          </div>
        </v-overlay>
        <!--      <div class="d-flex justify-space-between">-->
        <!--        <div class="d-flex">-->
        <!--          <div>-->
        <!--            <v-row-->
        <!--                no-gutters-->
        <!--                class="mb-1"-->
        <!--            >-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                    <span>-->
        <!--                    회원가입:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    회원입금:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    회원출금:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    회원문의:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->

        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    롤링전환:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->

        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--            <v-row-->
        <!--                no-gutters>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    총판 입금:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    총판 출금:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    총판문의:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    총판정산:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0-->
        <!--                  </span>-->

        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--            </v-row>-->

        <!--          </div>-->
        <!--          <div class="ml-2">-->
        <!--            <v-row-->
        <!--                no-gutters-->
        <!--                class="mb-1"-->
        <!--            >-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                    <span>-->
        <!--                    알림 라이브:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0/0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    알림 미니:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0/0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    알림 가상:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0/0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->

        <!--            </v-row>-->
        <!--            <v-row-->
        <!--                no-gutters-->
        <!--            >-->
        <!--              <v-col-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    관심회원 베팅:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0/0-->
        <!--                  </span>-->

        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    알림 슬롯:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0/0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--              <v-col-->
        <!--                  cols=""-->
        <!--                  class="px-1"-->
        <!--              >-->
        <!--                <v-card-->
        <!--                    class="mx-auto d-flex align-center justify_center font-weight-bold"-->
        <!--                    width="120"-->
        <!--                    min-height="40"-->
        <!--                    light-->
        <!--                >-->
        <!--                  <span>-->
        <!--                    알림 스포츠:-->
        <!--                  </span>-->
        <!--                  <span>-->
        <!--                    0/0-->
        <!--                  </span>-->
        <!--                </v-card>-->
        <!--              </v-col>-->
        <!--            </v-row>-->

        <!--          </div>-->
        <!--          <div>-->

        <!--          </div>-->

        <!--        </div>-->
        <!--        <div class="d-flex align-content-space-between">-->
        <!--          <div-->
        <!--              class="d-flex align-center font-weight-bold"-->
        <!--          >-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              접속자 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              {{ dashBoardItem.memberInfo.now | comma }}-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              금일 가입자 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              0-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              가입 유저 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--               {{ dashBoardItem.memberInfo.totalCount | comma }}-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              가입 첫충 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              0-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              로그인 실패횟수 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              0-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              금일 입금자수 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              0-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              금일베팅회원수 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              0-->
        <!--            </span>-->
        <!--            </div>-->
        <!--            <div class="mr-3">-->
        <!--            <span>-->
        <!--              금일베팅건수 :-->
        <!--            </span>-->
        <!--              <span>-->
        <!--              0-->
        <!--            </span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div-->
        <!--              class="d-flex align-center font-weight-bold"-->
        <!--          >-->
        <!--            <v-btn-->
        <!--                class="ml-2"-->
        <!--                min-width="90"-->
        <!--                icon-->
        <!--            >-->
        <!--              <v-icon>mdi-bell-ring</v-icon>-->
        <!--              &lt;!&ndash;            mdi-bell&ndash;&gt;-->
        <!--              &lt;!&ndash;            mdi-bell-ring&ndash;&gt;-->
        <!--              &lt;!&ndash;            mdi-bell-off&ndash;&gt;-->
        <!--            </v-btn>-->


        <!--          </div>-->
        <!--        </div>-->

        <!--      </div>-->
        <v-row no-gutters
               v-if="Object.keys(dashBoardItem).length >0"
        >
          <!--        충환전-->

          <v-col
              cols="3"
          >
            <div class="statusBox"

            >
              <div class="num_box">
                <ul class="co_test">
                  <li class="statusHeader">
                    충전
                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      :class="dashBoardItem.chargeInfo.requestedCount > 0 ? 'alert blink' : ''"
                      @click="move('/deposit/All')">
                    <div>
                      <span class="c_red">신규</span>
                      <p class="c_show c_red">
                      <span class="size_txt c_red"
                            :class="dashBoardItem.chargeInfo.requestedCount > 0 ? 'blink' : ''">
                      {{ dashBoardItem.chargeInfo.requestedCount | comma }}
                     </span>건
                      </p>
                    </div>


                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/deposit/All')">
                    <div>
                      <span class="c_blue">대기</span>
                      <p class="c_show"><span class="size_txt">
                            {{ dashBoardItem.chargeInfo.checkedCount | comma }}
                            </span>건</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/deposit/All')">
                    <div>
                      <span class="c_blue">완료</span>
                      <p class="c_show"><span class="size_txt">
                            {{ dashBoardItem.chargeInfo.completedCount | comma }}
                            </span>건</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/deposit/All')">
                    <div>
                      <span class="c_blue">취소</span>
                      <p class="c_show"><span class="size_txt">
                            {{ dashBoardItem.chargeInfo.rejectedCount | comma }}</span>건</p>
                    </div>

                  </li>

                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">총 충전액</span>
                      <p class="c_show">
                            <span class="size_txt">
                              {{ dashBoardItem.chargeInfo.totalAmount | comma }}
                          </span>원</p>
                    </div>

                  </li>
                </ul>
              </div>
              <v-divider></v-divider>
              <div class="num_box bottom">
                <ul class="co_test">
                  <li class="statusHeader">
                    환전
                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/withdrawal/All')"
                      :class="dashBoardItem.exchangeInfo.requestedCount > 0 ? 'alert blink' : ''"
                  >
                    <div>
                      <span class="c_red">신규</span>
                      <p class="c_show c_red"><span class="size_txt c_red"
                                                    :class="dashBoardItem.exchangeInfo.requestedCount> 0 ? 'blink' : ''">
                          {{ dashBoardItem.exchangeInfo.requestedCount | comma }}
                          </span>건</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/withdrawal/All')">
                    <div>
                      <span class="c_blue">대기</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.exchangeInfo.checkedCount | comma }}
                        </span>건</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/withdrawal/All')">
                    <div>
                      <span class="c_blue">완료</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.exchangeInfo.completedCount | comma }}
                        </span>건</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/withdrawal/REJECT')">
                    <div>
                      <span class="c_blue">취소</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.exchangeInfo.rejectedCount | comma }}
                        </span>건</p>
                    </div>

                  </li>

                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">총 환전액</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.exchangeInfo.totalAmount | comma }}
                          </span>원</p>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
          </v-col>

          <v-col
              cols="4"
          >
            <div class="statusBox">
              <div class="num_box">
                <ul class="co_test">
                  <li class="statusHeader">
                    회원
                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/memberNew')"
                      :class="dashBoardItem.memberInfo.newCount > 0 ? 'alert blink' : ''"
                  >
                    <div>
                      <span class="c_red">신규</span>
                      <p class="c_show c_red"><span class="size_txt c_red"
                                                    :class="dashBoardItem.memberInfo.newCount > 0 ? 'blink' : ''">
                          {{ dashBoardItem.memberInfo.newCount | comma }}</span>명</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                  >
                    <div>
                      <span class="c_blue">금일가입</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.todayCount | comma }}
                          </span>명</p>
                    </div>

                  </li>

                  <li

                      class="cursor-pointer topBarContents"
                      @click="move('/member')">
                    <div>
                      <span class="c_blue">총회원</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalCount | comma }}
                          </span>명</p>
                    </div>

                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/logOn')">
                    <div>
                      <span class="c_red">로그인</span>
                      <p class="c_show c_red"><span class="size_txt c_red">
                          {{ dashBoardItem.memberInfo.now | comma }}
                          </span>명</p>
                    </div>

                  </li>
                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">게임머니(회원)</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalMoney | comma }}
                          </span>원</p>
                    </div>

                  </li>
                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">포인트(회원)</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalMileage | comma }}
                          </span>P</p>
                    </div>
                  </li>
                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">롤링금(회원)</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalCommission | comma }}
                          </span>P</p>
                    </div>
                  </li>
                </ul>
              </div>
              <v-divider class=""></v-divider>
              <div class="num_box bottom">
                <ul class="co_test">
                  <li class="statusHeader">
                    Q&A<br>게시판
                  </li>
                  <li
                      class="cursor-pointer topBarContents"
                      @click="move('/customer')"
                      :class="dashBoardItem.customerCenterInfo.newCount > 0 ? 'alert blink' : ''"
                  >
                    <div>
                      <span class="c_red">신규</span>
                      <p class="c_show c_red"><span class="size_txt c_red"
                                                    :class="dashBoardItem.customerCenterInfo.newCount > 0 ? 'blink' : ''">
                          {{
                          dashBoardItem.customerCenterInfo.newCount | comma
                        }} / {{ dashBoardItem.customerCenterInfo.checkedCount | comma }}
                          </span>건</p>
                    </div>

                  </li>
                  <li class="topBarContents">
                    <div>
                      <span class="c_red">완료</span>
                      <p class="c_show c_red"><span class="size_txt c_red">
                          {{ dashBoardItem.customerCenterInfo.completedCount | comma }}
                          </span>건</p>
                    </div>

                  </li>
                  <li class="topBarContents">
                    <div>
                      <span class="c_red">게시물</span>
                      <p class="c_show c_red"><span class="size_txt c_red">
                          {{ dashBoardItem.customerCenterInfo.postCount | comma }}
                          </span>개</p>
                    </div>

                  </li>
                  <li></li>

                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">게임머니(파트너)</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalAgencyMoney | comma }}
                          </span>원</p>
                    </div>

                  </li>
                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">포인트(파트너)</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalAgencyMileage | comma }}
                          </span>P</p>
                    </div>
                  </li>
                  <li class="topBarContents" style="min-width:120px">
                    <div>
                      <span class="c_blue">롤링금(파트너)</span>
                      <p class="c_show"><span class="size_txt">
                          {{ dashBoardItem.memberInfo.totalAgencyCommission | comma }}
                          </span>P</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>

          <v-col
              cols="5"
          >
            <div class="statusBox">
              <div class="num_box h-100">
                <ul class="co_test betting">
                  <li class="statusHeader">
                    <div>
                      <div>&nbsp;</div>
                      <div>베팅회원</div>
                      <div>베팅중</div>
                      <div>총베팅</div>
                      <div>베팅정산</div>
                    </div>

                  </li>
                  <li class="statusHeader">
                    <div>
                      <div>스포츠</div>
                      <div>
                          <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.sportsBetMembers | comma }}
                          </span>명
                        <!--                    (<span class="size_txt c_red blink">
                                                  {{ dashBoardItem.betInfo.sportsBetMembers | comma }}
                                                  </span>)
                                            <span class="mdi mdi-check-bold"></span>-->
                      </div>
                      <div>
                        <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.sportsBetAmount | comma }}
                          </span>원
                      </div>
                      <div>
                        <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.sportsTotalAmount | comma }}
                          </span>원
                      </div>
                      <div>
                        <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.sportsProfitAmount | comma }}
                          </span>원
                      </div>
                    </div>

                  </li>
                  <li class="statusHeader">
                    <div>
                      <div>인플레이</div>
                      <div>
                          <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.inplayBetMembers | comma }}
                          </span>명
                        <!--                    (<span class="size_txt c_red blink">
                                                  {{ dashBoardItem.betInfo.inplayBetMembers | comma }}
                                                  </span>)
                                            <span class="mdi mdi-check-bold"></span>-->
                      </div>
                      <div>
                        <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.inplayBetAmount | comma }}
                          </span>원
                      </div>
                      <div>
                        <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.inplayTotalAmount | comma }}
                          </span>원
                      </div>
                      <div>
                        <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.inplayProfitAmount | comma }}
                          </span>원
                      </div>
                    </div>

                  </li>
                  <li class="statusHeader">
                    <div>
                      <div>카지노</div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.casinoBetMembers | comma }}
                          </span>명
                      </div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.casinoBetAmount | comma }}
                          </span>원
                      </div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.casinoTotalAmount | comma }}
                         </span>원
                      </div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.casinoProfitAmount | comma }}
                          </span>원
                      </div>
                    </div>

                  </li>
                  <li class="statusHeader">
                    <div>
                      <div>가상</div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.virtualGameBetMembers | comma }}
                          </span>명
                      </div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.virtualGameBetAmount | comma }}
                          </span>원
                      </div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.virtualGameTotalAmount | comma }}
                          </span>원
                      </div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.virtualGameProfitAmount | comma }}
                          </span>원
                      </div>
                    </div>

                  </li>
                  <li class="statusHeader">
                    <div>
                      <div>미니게임</div>
                      <div>
                      <span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.virtualGameBetMembers | comma }}
                          </span>명
                      </div>
                      <div>
                      <span
                          class="size_txt c_red">{{ dashBoardItem.betInfo.miniGameBetAmount|comma }}
                          </span>원</div>
                      <div><span class="size_txt c_red">
                          {{ dashBoardItem.betInfo.miniGameTotalAmount|comma }}
                          </span>원</div>
                      <div>
                      <span
                          class="size_txt c_red">{{ dashBoardItem.betInfo.miniGameProfitAmount|comma }}
                          </span>원
                      </div>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
            <div v-html="soundCharge"></div>
            <div v-html="soundExchange"></div>
            <div v-html="soundCustomer"></div>
            <div v-html="soundMember"></div>
            <div v-html="soundAttentionMember"></div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
// import axios from "axios";


export default {
  name: 'StatusBoard',
  data: () => ({

    duration: 1,
    scaleClass: true,

  }),
  methods: {

    move: function (item) {
      this.$emit('move', item)
    },

  },

  mounted() {
  },


  props: {
    dashBoardItem: {
      type: Object
    },
    soundCharge: {
      type: String
    },
    soundExchange: {
      type: String
    },
    soundCustomer: {
      type: String
    },
    soundMember: {
      type: String
    },
    overlay: {
      type: Boolean
    },
    soundAttentionMember: {
      type: String
    }

  },
  computed: {
    themeFontColor: function () {
      return this.vuetify.theme.dark ? 'black-font' : ''
    },
  },

}
</script>

<style>
.theme--light.v-card > .v-card__text {
  color: #333333 !important;
}

.theme--dark.v-card > .v-card__text {
  color: #FFFFFF !important;
}

.num_box li {
  min-width: 40px;
}

.statusBox {
  height: 138px;
//border-radius: 10px;
  margin-right: 0px;
  box-shadow: 3px 3px 9px 3px #2c2c2c;
  background-color: #322f2f;
  overflow: hidden;
  font-family: GmarketSansLight;
  font-size:11px;
}

.statusBox .num_box.bottom {
  background-color: #444141;
}

.statusBox .num_box .co_test {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: normal;
  justify-content: space-around;
  padding: 6px 0px;
}

.statusBox li {list-style:none}

.statusBox li p {font-size:11px}

.statusHeader {
  font-family: GmarketSansMedium;
  font-size:11px;
  min-width:30px;
}

.betting li:not(:first-child)
{
  height:120px;
  background-color: #444141;
  padding:1px 5px;
  box-shadow: 0px 1px 2px #656060;
  min-width:110px;
}

.betting li p {
  line-height:8px;
  margin-top:6px;
}

.statusBox {margin:1px;}

.num_box {
  height: 69px;
}
.alert {
  color: gold;
}

.c_show {
  font-size: 11px !important;
}

.blink {
  color: gold !important;
}

</style>

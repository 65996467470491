<template>

  <v-simple-table
                  dense
  >
    <template v-slot:default>
      <thead>
      <tr class="game-info">
        <th class="text-center" style="width:15%">
          경기시간
        </th>
        <th class="text-center">
          리그
        </th>
        <th class="text-center">
          게임 타입
        </th>
        <th class="text-center" style="width:20%">
          승(HOME)
        </th>
        <th class="text-center" style="width:10%">
          무(기준점)
        </th>
        <th class="text-center" style="width:20%">
          패(AWAY)
        </th>
        <th class="text-center">
          스코어
        </th>
        <th class="text-center">
          상태
        </th>
        <th class="text-center">
          취소
        </th>
      </tr>
      </thead>
      <tbody>
      <!--당첨-->
      <tr
          v-for="(betItem,i) in myBetList"
          :key="i"
          class="game-info">

        <td class="text-center">
          <div
              class="ovm-ClassificationBarButton_Icon cil-ClassificationIconLarge cil-ClassificationIconLarge-1 "></div>
          {{ betItem.matchedAt }}
        </td>
        <td class="text-center">
          {{ betItem.league }}
        </td>
        <td class="text-center">
          {{ betItem.gameCodeText }}
        </td>
        <td
            class="text-center "
            :class="betItem.whichBet === 'HOME' ? 'betSelected font-weight-bold': ''">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{
                betItem.special === '' || betItem.special === null ? betItem.teamHome : betItem.teamHome + ' [' + betItem.special + ']'
              }}
              <v-icon
                  v-if="betItem.gameCode ==='OVER_UNDER'"
                  medium
                  color="red"
                  style="font-size:15px"
              >mdi-arrow-up
              </v-icon>
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsHome }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DRAW' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex align-center justify-center">
            <span v-if="betItem.oddsDraw === 0 && betItem.gameCode.includes('MATCH')">VS</span>
            <span v-else-if="betItem.oddsDraw !== 0 && betItem.gameCode.includes('MATCH')">{{ betItem.oddsDraw }}</span>
            <span v-else>{{ betItem.handicap }}</span>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'AWAY' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              {{ betItem.oddsAway }}
            </div>
            <div class="d-flex align-center text-right">
              <v-icon
                  v-if="betItem.gameCode ==='OVER_UNDER'"
                  medium
                  color="indigo"
                  style="font-size:15px"
              >mdi-arrow-down
              </v-icon>
              {{
                betItem.special === '' || betItem.special === null ? betItem.teamAway : '[' + betItem.special + '] ' + betItem.teamAway
              }}
            </div>
          </div>
        </td>
        <td class="text-center">
          <div v-if="betItem.scoreHome !== null && betItem.scoreHome !== null">
            {{ betItem.scoreHome }} : {{ betItem.scoreAway }}
          </div>
        </td>
        <td class="text-center">
                <span>
                  {{ betItem.statusValue }}
                </span>
        </td>
        <td class="text-center">
          <v-btn
              tile
              small
              color="error"
              @click="cancelBetItem(betItem.betItemId)"
              :disabled="(betItem.status !== 'READY' || betItem.status === 'DENY') || (menuCode === 'VSOCCER' || menuCode === 'VBASKETBALL')"
          >관리자취소
          </v-btn>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>

</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Array
    },
    menuCode: {
      type: String
    }

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },


  }

}
</script>

<style scoped>
th, td {
  font-size: 11px !important;
}


.win > td {
  background-color: #f7f4f214;
  color: #FFFFFF;
}

.lose > td {
  background-color: rgba(245, 241, 238, 0.08);
  color: #8d7f7f;
}


button {
  margin-right: 5px;
}


tr:hover {
  background-color: transparent !important;

}


.dog {
  margin: 2px;
  padding: 5px;
  border: 1px solid #6e6e6e;
}

.dog div:nth-child(2n+1) {

}

.dog div:nth-child(2n) {
  min-width: 40px;
}
</style>

<template>
  <v-simple-table
    dense
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center" style="width:15%">
          경기시간
        </th>
        <th class="text-center">
          리그
        </th>
        <th class="text-center">
          게임 타입
        </th>
        <th class="text-center" style="width:50%" colspan="3">
          팀명
        </th>
        <th class="text-center">
          결과
        </th>
        <th class="text-center">
          상태
        </th>
        <th class="text-center">
          취소
        </th>
      </tr>
      </thead>
      <tbody
          v-for="(betItem,i) in myBetList"
          :key="i"
      >
      <!--당첨-->
      <tr
          class="game-info">

        <td rowspan="2"
            class="text-center">
          <div
              class="ovm-ClassificationBarButton_Icon cil-ClassificationIconLarge cil-ClassificationIconLarge-1 "></div>
          {{ betItem.matchedAt }}
        </td>
        <td
            rowspan="2"
            class="text-center">
          {{ betItem.league }}
        </td>
        <td
            rowspan="2"
            class="text-center">
          {{ betItem.gameCodeText }}
        </td>
        <td
            class="text-center "
            :class="betItem.whichBet === 'DOG_1' ? 'betSelected font-weight-bold': ''">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.teamHome }}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsHome }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_2' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team3 }}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsDraw }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_3' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.teamAway }}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsAway }}
            </div>
          </div>
        </td>
        <td
            rowspan="2"
            class="text-center">
          <span>
                  {{
              betItem.wonBet === 'DOG_1' ? betItem.teamHome :
                  (betItem.wonBet === 'DOG_2' ? betItem.team3 :
                      (betItem.wonBet === 'DOG_3' ? betItem.teamAway :
                          (betItem.wonBet === 'DOG_4' ? betItem.team4 :
                              (betItem.wonBet === 'DOG_5' ? betItem.team5 :
                                  (betItem.wonBet === 'DOG_6' ? betItem.team6 : '')))))
            }}
                </span>

        </td>
        <td
            rowspan="2"
            class="text-center">
                <span>
                  {{ betItem.statusValue }}
                </span>
        </td>
        <td
            rowspan="2"
            class="text-center">
          <v-btn
              tile
              small
              color="error"
              @click="cancelBetItem(betItem.betItemId)"
              disabled
          >관리자취소
          </v-btn>
        </td>
      </tr>
      <tr class="game-info">
        <td
            class="text-center "
            :class="betItem.whichBet === 'DOG_4' ? 'betSelected font-weight-bold': ''">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team4 }}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsExtra1 }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_5' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team5 }}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsExtra2 }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_6' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team6 }}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsExtra3 }}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Array
    },

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },


  }

}
</script>

<style scoped>
th, td {
  font-size: 14px !important;
}

.win > td {
  background-color: #f7f4f214;
  color: #FFFFFF;
}

.lose > td {
  background-color: rgba(245, 241, 238, 0.08);
  color: #8d7f7f;
}


button {
  margin-right: 5px;
}


tr:hover {
  background-color: transparent !important;

}


.dog {
  margin: 2px;
  padding: 5px;
  border: 1px solid #6e6e6e;
}

.dog div:nth-child(2n+1) {

}

.dog div:nth-child(2n) {
  min-width: 40px;
}
</style>

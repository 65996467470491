<template>
  <div id="">
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        allow-overflow
        bottom
        right
        offset-y
        max-width="100%"
        origin="top right"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            class="ml-2"
            text
            v-bind="attrs"
            v-on="on"
            @click="openStatusBoard"
        >
          <v-badge
              bordered
              color="red"
              overlap
          >
            <template v-slot:badge>
              <span>0</span>
            </template>
            <v-icon> mdi-monitor-dashboard</v-icon>
          </v-badge>
        </v-btn>
      </template>

        <v-card
            class="text-center mb-0 "
            width="100%"
            ref="statusBoard"
        >

            <v-card-text>

              <div class="text-left">
                <div class="mb-2">
                  <strong>충/환전</strong>
                </div>

                <v-simple-table
                    class=""
                >
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center">
                      </th>
                      <th class="text-center">
                        충전
                      </th>
                      <th class="text-center">
                        환전
                      </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                      <td>
                        신규
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.chargeInfo.requestedCount | comma }} 건
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.exchangeInfo.requestedCount | comma}} 건
                      </td>
                    </tr>
                    <tr>
                      <td>
                        대기
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.chargeInfo.checkedCount | comma}} 건
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.exchangeInfo.checkedCount | comma}} 건
                      </td>
                    </tr>
                    <tr>
                      <td>
                        완료
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.chargeInfo.completedCount | comma}} 건
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.exchangeInfo.completedCount | comma}} 건
                      </td>
                    </tr>
                    <tr>
                      <td>
                        취소
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.chargeInfo.rejectedCount | comma}} 건
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.exchangeInfo.rejectedCount | comma}} 건
                      </td>
                    </tr>
                    <tr>
                      <td>
                        총 금액
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.chargeInfo.totalAmount | comma }} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.exchangeInfo.totalAmount | comma}} 원
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <v-divider class="my-2 secondary"/>

              <div class="text-left">
                <div class="mb-2">
                  <strong>회원</strong>
                </div>
                <v-simple-table
                    class=""
                >
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>
                        신규
                      </td>
                      <td class="text-right">
                        {{ dashBoardItem.memberInfo.newCount | comma }} 명
                      </td>

                    </tr>
                    <tr>
                      <td>
                        총 회원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.memberInfo.totalCount | comma}} 명
                      </td>

                    </tr>

                    <tr>
                      <td>
                        로그인
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.memberInfo.now | comma }} 명
                      </td>

                    </tr>
                    <tr>
                      <td>
                        게임머니(회원)
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.memberInfo.totalMoney | comma}} 원
                      </td>

                    </tr>
                    <tr>
                      <td>
                        포인트(회원)
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.memberInfo.totalMileage | comma}} P
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <v-divider class="my-2 secondary"/>

              <div class="text-left">
                <div class="mb-2">
                  <strong>게시판</strong>
                </div>
                <v-simple-table
                    class=""
                >
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>
                        신규
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.customerCenterInfo.newCount | comma}} 건
                      </td>

                    </tr>
                    <tr>
                      <td>
                        완료
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.customerCenterInfo.completedCount | comma}} 건
                      </td>
                    </tr>
                    <tr>
                      <td>
                        게시물
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.customerCenterInfo.postCount | comma}} 개
                      </td>

                    </tr>
                    <tr>
                      <td>
                        게임머니(관리자)
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.memberInfo.totalAgencyMoney | comma}} 원
                      </td>

                    </tr>
                    <tr>
                      <td>
                        포인트(관리자)
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.memberInfo.totalAgencyMileage | comma}} P
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <v-divider class="my-2 secondary"/>

              <div class="text-left">
                <div class="mb-2">
                  <strong>배팅</strong>
                </div>

                <v-simple-table
                    class=""
                >
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center">
                      </th>
                      <th class="text-center">
                        배팅 중
                      </th>
                      <th class="text-center">
                        총 베팅
                      </th>
                      <th class="text-center">
                        베팅 정산
                      </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                      <td>
                        스포츠
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.sportsBetAmount | comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.sportsTotalAmount | comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.sportsProfitAmount | comma}} 원
                      </td>
                    </tr>
                    <tr>
                      <td>
                        카지노
                      </td>
                      <td class="text-right">
                        {{ dashBoardItem.betInfo.casinoBetAmount | comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.casinoTotalAmount | comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.casinoProfitAmount | comma}} 원
                      </td>
                    </tr>
                    <tr>
                      <td>
                        가상
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.virtualGameBetAmount | comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.virtualGameTotalAmount | comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.virtualGameProfitAmount | comma }} 원
                      </td>
                    </tr>
                    <tr>
                      <td>
                        미니게임
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.miniGameBetAmount|comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.miniGameTotalAmount|comma}} 원
                      </td>
                      <td class="text-right">
                        {{dashBoardItem.betInfo.miniGameProfitAmount|comma}} 원
                      </td>
                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <v-divider class="my-2 secondary"/>




            </v-card-text>

        </v-card>
    </v-menu>
    <div v-html="soundCharge"></div>
    <div v-html="soundExchange"></div>
    <div v-html="soundCustomer"></div>
    <div v-html="soundMember"></div>
  </div>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'
// Vuex

export default {
  name: 'DashboardCoreSettings',
  data: () => ({
    menu: false,
    calcCartHeight:0
  }),
  props: {
    dashBoardItem: {
      type:Object
    },
    soundCharge:{
      type:String
    },
    soundExchange:{
      type:String
    },
    soundCustomer:{
      type:String
    },
    soundMember:{
      type:String
    }
  },
  methods: {
    move:function(item){
      this.$emit('move',item)
    },

    openStatusBoard: async function () {
      await this.$nextTick();
      console.log('window',window.innerHeight)
      setTimeout(() => {
      }, 400)

    },
    onResize: async function () {
      await this.$nextTick();
      this.calcCartHeight = window.innerHeight - 56
    }

  },
  mounted() {
    this.onResize()
  },
  computed: {
    cartStyle() {
      return {
        height: `${this.calcCartHeight}px`,
      }
    },
  },
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>

import {min,max,required,confirmed,max_value,min_value,numeric,between} from 'vee-validate/dist/rules'

import {extend} from "vee-validate";
extend('required', {
    ...required,
    // message: '{_field_} can not be empty',
    message:"값을 입력해주세요.",
})
extend("confirmed", {
    ...confirmed,
    message: "비밀번호가 일치하지 않습니다. 비밀번호를 확인해주세요."
});
extend("min", {
    ...min,
    message: " ",

});
extend("max", {
    ...max,
    message: "최대 {length} 자리 이하로 입력해주세요.",

});
extend("max_value", {
    ...max_value,
    message: "최대 수수료는 {_rule_} % 입니다",

});


extend("min_value", {
    ...min_value,
    message: "최소 수수료는 {field} % 입니다",
});
extend("numeric", {
    ...numeric,
    message: "숫자만 입력해주세요",
});

// extend("between", {
//     ...between,
//     message: "최소 수수료는 {value}% 입니다",
// });
//
// const isBetween = (value, { min, max } = {}) => {
//     return Number(min) <= value && Number(max) >= value;
// };
// const paramNames = ['min', 'max'];
//
// extend("between", isBetween,{
//     paramNames
// });

extend('between', {
    validate(value, { min, max }) {
        return parseFloat(value) >= parseFloat(min) && parseFloat(value) <= parseFloat(max);
    },
    params: ['min', 'max'],
    message: '{min}% ~ {max}% 로 설정 가능합니다.'
});
extend("phone", {
    message: "올바른 값을 입력해주세요.",
    validate: (value) => {
        if (value.split('-')[0] === '010'
            || value.split('-')[0] === '011'
            || value.split('-')[0] === '016'
            || value.split('-')[0] === '017'
            || value.split('-')[0] === '019') {
            return true;
        } else {
            return false;
        }
    },
});

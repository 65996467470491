<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'd-grid' :''">
    <div class="d-flex notiContent" >
      <h2>ADMIN</h2>
    </div>
    <div class="d-flex notiContent" @click="goIndex">
      <h3 class="font-red">HOME</h3>
    </div>
    <div class="d-flex notiContent" @click="logout">
      <h3 class="font-grey">로그아웃</h3>
    </div>
<!--    <div style="min-width:105px" class="d-flex" >

      <div class="mr-5"><v-icon @click="goIndex">mdi-home</v-icon></div>
      <div><v-icon @click="logout">mdi-logout</v-icon></div>

    </div>-->
<!--    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            class="ml-2"
            min-width="90"
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-badge
              bordered
              color="red"
              overlap
              v-if="Object.keys(dashBoardItem).length >0"
          >
            <template v-slot:badge>
              <span>{{dashBoardItem.customerCenterInfo.newCount}}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
          flat
          nav
      >
        <v-list-item
            v-for="(n, i) in notifications"
            :key="i"
            link
        >
          <v-list-item-content>
            <v-list-item-title>{{ n }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>-->
  </div>

</template>

<script>
  export default {
    name: 'DefaultNotifications',

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),
    props: {
        dashBoardItem: {
          type:Object
        }
    },
    methods: {
      goIndex: function () {
        if (this.$route.path !== '/') {
          this.$router.push({name: "index"})
        } else {
          window.location.reload(true);
        }

      },
      logout: function (){
        this.$logout();
      }
    }
  }
</script>
<style>
.notiContent {
  width: 96px;
}
</style>
<template>
<div>
  <v-simple-table
    dense
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center" style="width:15%">
          시간정보
        </th>
        <th class="text-center">
          리그
        </th>
        <th class="text-center" style="width:8%">
          마켓
        </th>
        <th class="text-center" style="width:18%">
          승(HOME)
        </th>
        <th class="text-center" style="width:8%">
          무(기준점)
        </th>
        <th class="text-center" style="width:18%">
          패(AWAY)
        </th>
        <th class="text-center">
          스코어
        </th>
        <th class="text-center">
          상태
        </th>
        <th class="text-center">
          취소
        </th>
      </tr>
      </thead>
      <tbody>
      <!--당첨-->
      <tr
          v-for="(betItem,i) in myBetList"
          :key="i"
          class="game-info">

        <td class="text-center">
          <div
              class="ovm-ClassificationBarButton_Icon cil-ClassificationIconLarge cil-ClassificationIconLarge-1 "></div>
          <div>{{ betItem.matchedAt }}</div>
          <div>
            {{ JSON.parse(betItem.extraInfo1)['timer'].time_mark_kor }}
            {{ JSON.parse(betItem.extraInfo1)['timer'].time_mm }} :
            {{ JSON.parse(betItem.extraInfo1)['timer'].time_ss }}
          </div>
        </td>
        <td class="text-center">
          {{ betItem.league }}
        </td>
        <td class="text-center">
          {{ betItem.gameCodeText }}
        </td>
        <td
            class="text-center "
            :class="betItem.whichBet === 'BUY1' ? 'betSelected font-weight-bold': ''">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              <span v-if="betItem.gameCodeText.includes('짝홀')">짝</span>
              <span v-else>
              {{
                  betItem.team1
                }}
              </span>
              <v-icon
                  v-if="betItem.gameCodeText.includes('오버언더')"
                  medium
                  color="red"
                  style="font-size:15px"
              >mdi-arrow-up
              </v-icon>
            </div>
            <div class="d-flex align-center">
              {{ betItem.odds1 }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'BUY2' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex align-center justify-center">
            <span v-if="betItem.odds2 === 0 && betItem.base === ''">VS</span>
            <span v-else-if="betItem.odds2 > 0">{{ betItem.odds2 }}</span>
            <span v-else>{{ betItem.base }}</span>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'BUY3' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              {{ betItem.odds3 }}
            </div>
            <div class="d-flex align-center text-right">
              <v-icon
                  v-if="betItem.gameCodeText.includes('오버언더')"
                  medium
                  color="indigo"
                  style="font-size:15px"
              >mdi-arrow-down
              </v-icon>
              <span v-if="betItem.gameCodeText.includes('짝홀')">홀</span>
              <span v-else>
              {{
                  betItem.team2
                }}
              </span>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div v-if="betItem.sports === '축구' && betItem.gameCode === 'HANDICAP'">
            {{ betItem.stampScore1 }} : {{ betItem.stampScore2 }} (베팅시점)
          </div>
          <div>
            {{ betItem.score1 }} : {{ betItem.score2 }}
          </div>
        </td>
        <td class="text-center">
          <span>
            <span>
              {{ betItem.statusValue }}
            </span>
          </span>
        </td>
        <td class="text-center">
          <v-btn
              tile
              small
              color="error"
              @click="cancelBetItem(betItem.betItemId,'INPLAY')"
              style="font-size:10px;"
              :disabled="(betItem.status !== 'READY' || betItem.status === 'DENY' )"
          >관리자취소
          </v-btn>
          <v-btn
              tile
              small
              color="error"


              @click="openResetBetItem(betItem)"
              style="font-size:10px;"
              :disabled="(betItem.status !== 'WIN' && betItem.status !== 'LOSE' && betItem.status !== 'DRAW')"
          >재처리
          </v-btn>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>

</div>
</template>

<script>

export default {
  name: "SportsPc",
  components:{

  },
  data: () => ({
    dialog: {

      alertText: '',
      alertActionType: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
      bet: false,
      betData: {}
    },
  }),
  props: {
    myBetList: {
      type: Array
    },
    menuCode: {
      type: String
    },


  },
  methods: {
    cancelBetItem: function (id, code) {
      this.$emit('cancelBetItem', id, code)
    },
    openResetBetItem: function (data) {
      this.$emit('openResetBetItem',data)
    },
  }

}
</script>

<style scoped>
th, td {
  font-size: 11px !important;
}


.win > td {
  background-color: #f7f4f214;
  color: #FFFFFF;
}

.lose > td {
  background-color: rgba(245, 241, 238, 0.08);
  color: #8d7f7f;
}


button {
  margin-right: 5px;
}


tr:hover {
  background-color: transparent !important;

}


.dog {
  margin: 2px;
  padding: 5px;
  border: 1px solid #6e6e6e;
}

.dog div:nth-child(2n+1) {

}

.dog div:nth-child(2n) {
  min-width: 40px;
}
</style>

<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="1500px"
      content-class="betHistoryDialog"

  >
    <v-card
      height="100%"
    >
      <v-card-title class="justify-space-between  pb-3">
        <div class="text-h5">
          배팅 내역
        </div>
        <div>
          <v-btn
              icon
              class=""
              x-large
              color="white"
              @click="toggleDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <bet-preview-pc
          :useSmmary="false"
          :search="search"
          :betList="betContent"
          :menuCodeList="menuCodeList"
          :betDetailList="betDetailList"
          :memoContent="memoContent"
          v-on:getBetDetailData="getBetDetailData"
          v-on:cancelBet="cancelBet"
          v-on:cancelBetItem="cancelBetItem"
          v-on:goMemberDetail="goMemberDetail"
          v-on:getMemo="getMemo"
          v-on:searchData="searchData"
          ref="betList"
      ></bet-preview-pc>
<!--      <bet-preview-mobile-->
<!--          v-else-->
<!--          :search="search"-->
<!--          :betList="betContent"-->
<!--          :menuCodeList="menuCodeList"-->
<!--          :betDetailList="betDetailList"-->
<!--          :memoContent="memoContent"-->
<!--          v-on:getBetDetailData="getBetDetailData"-->
<!--          v-on:cancelBet="cancelBet"-->
<!--          v-on:cancelBetItem="cancelBetItem"-->
<!--          v-on:goMemberDetail="goMemberDetail"-->
<!--          v-on:getMemo="getMemo"-->
<!--          v-on:searchData="searchData"-->
<!--          ref="betList"-->
<!--      ></bet-preview-mobile>-->
      <div
          :class="$vuetify.breakpoint.mdAndUp ? 'mt-7': 'ma-5'"
          class=" text-center"
      >
        <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="20"
            @input="next"
            color="#4c4c4c"
        ></v-pagination>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import BetPreviewPc from "@/components/Sport/BetHistory/Preview/Pc";
import BetPreviewMobile from "@/components/Sport/BetHistory/Preview/Mobile";
// import axios from "axios";


export default {
  name: 'betDialogPc',
  data() {
    return {
      rules: {
        nullCheck: v => !!v || '',
      },
      page: 1,
      pageLength: this.betList.totalPages,
      total: this.betList.totalElements,
      isEmpty: this.betList.empty,
      search: {
        use: true,
        usedCalendar: true,
        usedAgency: false,
        usedMemberStatus: false,
        usedBetStatus: true,
        startDate: (new Date(new Date(new Date().setDate(new Date().getDate() -1)) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        defaultSelectValue: '',
        defaultSelectList: [],
        betStatusValue: '',
        betStatusList: [
          {text: '전체', value: ''},
          {text: '진행중', value: 'BET'},
          {text: '적중', value: 'WON'},
          {text: '미적중', value: 'NOT_WON'},
          {text: '취소', value: 'CANCEL'},
        ],
        // usedMenuCode: true,
        // menuCodeListValue :'',
        // menuCodeList : [
        //   {text:'전체',value:''},
        //   {text:'스포츠 게임',value:'SPORTS_GAME'},
        //   {text:'매치',value:'MATCH'},
        //   {text:'핸디캡',value:'HANDICAP'},
        //   {text:'크로스',value:'CROSS'},
        //   {text:'스페셜',value:'SPECIAL'},
        //   {text:'라이브',value:'LIVE'},
        //   {text:'인플레이',value:'INPLAY'},
        //   {text:'미니게임',value:'MINI_GAME'},
        //   {text:'파워볼',value:'POWERBALL'},
        //   {text:'파워볼 5분',value:'POWERBALL5M'},
        //   {text:'파워볼 3분',value:'POWERBALL3M'},
        //   {text:'스피드키노',value:'SPEEDKENO'},
        //   {text:'가상 게임',value:'VIRTUAL_GAME'},
        //   {text:'가상 축구',value:'VSOCCER'},
        //   {text:'가상 농구',value:'VBASKETBALL'},
        //   {text:'개경주',value:'VDOGRACING'},
        //   {text:'카지노',value:'CASINO'},
        //   {text:'카지노 라이브',value:'CASINO_LIVE'},
        //   {text:'슬롯 게임',value:'CASINO_SLOT'},
        // ],

        usedSortType: true,
        sortTypeListValue: null,
        sortTypeList: [
          {text: '시간', value: null},
          {text: '베팅금', value: 'bet'},
          {text: '당첨금', value: 'hit'},
        ]

      },
      betContent: [],
      searchRequest: {},
      menuCodeList: [
        '스포츠',
        '승무패',
        '핸디캡',
        '크로스',
        '스페셜',
        '라이브',
        '인플레이',
        '미니게임',
        // '파워볼',
        '파워볼 5분',
        '파워볼 3분',
        '스피드키노',
        '가상 게임',
        '가상 축구',
        '가상 농구',
        '개경주',
        '카지노',
        '카지노 라이브',
        '슬롯 게임',


      ],
    }


  },

  components: {
    BetPreviewPc,
    BetPreviewMobile,

  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    betList: {
      type: Object
    },
    betDetailList: {
      type: Object
    },
    memoContent: {
      type: Object
    }


  },
  methods: {

    searchData: function (data) {
      console.log('searchdata',data)
      // {text:'스포츠 게임',value:'SPORTS_GAME'},
      // {text:'매치',value:'MATCH'},
      // {text:'핸디캡',value:'HANDICAP'},
      // {text:'크로스',value:'CROSS'},
      // {text:'스페셜',value:'SPECIAL'},
      // {text:'라이브',value:'LIVE'},
      // {text:'인플레이',value:'INPLAY'},
      // {text:'미니게임',value:'MINI_GAME'},
      // {text:'파워볼',value:'POWERBALL'},
      // {text:'파워볼 5분',value:'POWERBALL5M'},
      // {text:'파워볼 3분',value:'POWERBALL3M'},
      // {text:'스피드키노',value:'SPEEDKENO'},
      // {text:'가상 게임',value:'VIRTUAL_GAME'},
      // {text:'가상 축구',value:'VSOCCER'},
      // {text:'가상 농구',value:'VBASKETBALL'},
      // {text:'개경주',value:'VDOGRACING'},
      // {text:'카지노',value:'CASINO'},
      // {text:'카지노 라이브',value:'CASINO_LIVE'},
      // {text:'슬롯 게임',value:'CASINO_SLOT'},
      let menuCode = 'SPORTS_GAME'
      switch (data.menuCode) {
        case 0:
          menuCode = 'SPORTS_GAME'
          break;
        case 1:
          menuCode = 'MATCH'
          break;
        case 2:
          menuCode = 'HANDICAP'
          break;
        case 3:
          menuCode = 'CROSS'
          break;
        case 4:
          menuCode = 'SPECIAL'
          break;
        case 5:
          menuCode = 'LIVE'
          break;
        case 6:
          menuCode = 'INPLAY'
          break;
        case 7:
          menuCode = 'MINI_GAME'
          break;
        case 8:
          menuCode = 'POWERBALL5M'
          break;
        case 9:
          menuCode = 'POWERBALL3M'
          break;
        case 10:
          menuCode = 'SPEEDKENO'
          break;
        case 11:
          menuCode = 'VIRTUAL_GAME'
          break;
        case 12:
          menuCode = 'VSOCCER'
          break;
        case 13:
          menuCode = 'VBASKETBALL'
          break;
        case 14:
          menuCode = 'VDOGRACING'
          break;
        case 15:
          menuCode = 'CASINO'
          break;
        case 16:
          menuCode = 'CASINO_LIVE'
          break;
        case 17:
          menuCode = 'CASINO_SLOT'
          break;
        default:
          menuCode = 'SPORTS_GAME'
          break;
      }
      this.page = 1
      this.searchRequest = {
        from: data.startDate,
        to: data.endDate,
        agencyId: data.agencyId,
        status: data.betStatus,
        sortType: data.sortType,
        menuCode: menuCode,
        page: this.page
      }
      switch (data.category) {
        case 'username':
          this.searchRequest.username = data.data
          break;
        case 'nickname':
          this.searchRequest.nickname = data.data
          break;
        case 'clientIp':
          this.searchRequest.clientIp = data.data
          break;
        default :
          break;
      }

      console.log('search', this.searchRequest)

      this.getBetList(this.betList.memberId, this.searchRequest)
    },

    mappingData: function () {
    },
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:dialog', !this.dialog);
      // this.$emit('input');
    },
    getBetList: function (id, data) {
      //this.getSummaryData()
      this.$emit('getBetList', id, data)
    },
    next: function (page) {
      this.searchRequest.page = page
      this.getBetList(this.betList.memberId, this.searchRequest)
    },
    goMemberDetail: function (item) {
      this.$emit('goMemberDetail', item)
    },

    getBetDetailData: function (id,menuCode) {
      this.$emit('getBetDetailData', id,menuCode)
    },
    editData: function (item) {
      this.$emit('editData', item)
    },
    deleteData: function (id) {
      this.$emit('deleteData', id)
    },
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    openDetail: function (id) {
      this.$refs.betList.openDetail(id)
      // this.$refs[id][0].openDetail(this.betDetailList[id])
    },
    getMemo: function (id) {
      this.$emit('getMemo', id)
    },

  },


  watch: {
    dialog: function () {
      if (this.dialog) {
        this.page = 1
        this.pageLength = this.betList.totalPages
        this.total = this.betList.totalElements
        this.isEmpty = this.betList.empty
        // this.betContent = this.betList.content
      } else {
        this.page = 1
        this.pageLength = 0
        this.total = 0
        this.printNumber = 1
        this.isEmpty = false
        this.$refs.betList.initSearchStatusUsingBetDialog()
        this.searchRequest = {}
      }
    },
    betList: {
      handler: function (val, oldVal) {
        this.betContent = val.content
        if (val.empty) {
          this.page = 1
          this.pageLength = 0
          this.total = 0
        } else {
          this.pageLength = val.totalPages
          this.total = val.total
        }
      },
      deep: true
    }
  },
  mounted() {


  },

}
</script>

<style>
.bet-info {
  background-color: #2f3232
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}
</style>

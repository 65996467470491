<template>
  <div>

    <!--당첨-->
    <v-card
        elevation="24"
    >
      <v-simple-table
          style="background-color: rgba(245, 241, 238, 0.08)"
      >
        <tbody
            v-for="(betItem,i) in myBetList"
            :key="i">
        <tr class="game-info">
          <td colspan="4" class="text-left">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <div class="pr-2">
                  [{{ betItem.gameCodeText }}]
                </div>
                <div>
                  {{ betItem.gameDate }} {{ betItem.round }} 회차
                </div>

              </div>
              <div>
                <div>
                  <v-btn
                      tile
                      color="black"
                      small
                      class=""
                      @click="cancelBetItem(betItem.betItemId)"
                      disabled
                  >관리자취소
                  </v-btn>
                </div>
              </div>
            </div>
          </td>

        </tr>
        <tr class="game-info">
          <td class="text-center "
              :class="betItem.whichBet === 'EVEN' || betItem.whichBet === 'SMALL' || betItem.whichBet === 'UNDER' ? 'betSelected font-weight-bold': ''"
              style="width:39%">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                {{
                  betItem.gameCode === 'P_ODD_EVEN' ? '파워볼 홀' :
                      (betItem.gameCode === 'P_OVER_UNDER' ? '파워볼 언더' :
                          (betItem.gameCode === 'BALL_SIZE' ? '일반볼 소' :
                              (betItem.gameCode === 'P_SUM_ODD_EVEN' ? '일반볼 합 홀' :
                                  (betItem.gameCode === 'P_SUM_OVER_UNDER' ? '일반볼 합 언더' : ''))))
                }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsHome }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'MEDIUM' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex align-center justify-center"
                 v-if="betItem.gameCode === 'BALL_SIZE'"
            >
              일반볼 중 {{betItem.oddsDraw}}
            </div>
            <div v-else>VS</div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'ODD' || betItem.whichBet === 'LARGE' || betItem.whichBet === 'OVER'? 'betSelected font-weight-bold': ''"
              style="width:39%"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                {{ betItem.oddsAway }}
              </div>
              <div class="d-flex align-center">
                {{
                  betItem.gameCode === 'P_ODD_EVEN' ? '파워볼 짝' :
                      (betItem.gameCode === 'P_OVER_UNDER' ? '파워볼 오버' :
                          (betItem.gameCode === 'BALL_SIZE' ? '일반볼 대' :
                              (betItem.gameCode === 'P_SUM_ODD_EVEN' ? '일반볼 합 짝' :
                                  (betItem.gameCode === 'P_SUM_OVER_UNDER' ? '일반볼 합 오버' : ''))))
                }}
              </div>
            </div>
          </td>
          <td class="text-center" style="width:20%">
            <div>
              {{ betItem.statusValue }}
            </div>
            <div>
              {{ betItem.wonBet }}
              <v-icon color="success" small v-if="betItem.status==='WIN'">mdi-circle-outline</v-icon>
              <v-icon color="error" small v-else-if="betItem.status==='LOSE'">mdi-close</v-icon>
              <v-icon color="white" small v-else-if="betItem.status==='DENY' || betItem.status.includes('CANCEL')">mdi-cancel</v-icon>
              <v-icon color="white" small v-else-if="betItem.status==='READY'">mdi-timer-sand</v-icon>
              <v-icon color="primary" small v-else-if="betItem.status==='INVALID'">mdi-triangle-outline</v-icon>
            </div>
          </td>
        </tr>

        </tbody>
      </v-simple-table>
    </v-card>
    <v-divider
        class="mt-4"
        dark
    ></v-divider>
  </div>

</template>

<script>
export default {
  name: "SportsMobile",
  props: {
    myBetList: {
      type: Array
    },

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },

  }
}
</script>

<style scoped>

</style>
<template>
  <v-card class="subMenu">
    <v-tabs
        v-model="tab"
        background-color="transparent"
        grow
        show-arrows
    >
      <v-tab
          v-for="(item, i) in siteMenuList"
          :key="i"
          link
          exact-path
          :to="item.path"

      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "SubMenu",
  data: () => ({
    tab: null,
    siteMenuList: [
      {title:'사이트 설정', path: '/setting/site'},
      {title:'게임/베팅 설정', path: '/setting/game'},
      {title:'조합베팅 설정', path: '/mixBetConfig'},
      {title:'등급별 설정', path: '/setting/grade'},
      {title:'배당 설정', path: '/setting/sports'},
      {title:'차단 IP 관리', path: '/setting/blockip'},
      {title:'은행관리', path: '/setting/bank'},
      {title:'입금계좌관리', path: '/setting/deposit'},
      {title:'비밀번호변경', path: '/setting/password'}
    ],
  }),

}
</script>

<style>

.v-application .subMenu .primary--text {
  color: gold !important;
  caret-color: gold !important;
}
.subMenu .v-tab {
  font-size: 13px !important;
  background: linear-gradient(to bottom, #54514e, #000000) #222;
  border-left: solid 1px rgba(255,255,255,0.1);
  border-right: solid 1px rgba(0,0,0,0.8);
}

.subMenu .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(255, 255, 255, 0.9);
}
</style>
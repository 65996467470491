import Vue from "vue";
import Router from "vue-router";
import AppBar from "./components/_Default/AppBar";

Vue.use(Router);


const requireAuthLogin = () => (from, to, next) => {
    let isAuthenticated = false;

    if (localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null) {
        isAuthenticated = true;
    }
    // if (localStorage.accessToken !== undefined) {
    //     isAuthenticated = true;
    // }

    if (isAuthenticated) return next('/');
    //메인페이지 완성전까지 사용
    // if (isAuthenticated) return next('/Customer');
    next();
}
const requireAuth = () => (from, to, next) => {
    let isAuthenticated = false;
    console.log(localStorage)
    console.log(localStorage.getItem('accessToken'))
    if (localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null) {
        isAuthenticated = true;
    }
    // if (localStorage.accessToken !== undefined) {
    //     isAuthenticated = true;
    // }
    // if (isAuthenticated) return next('/');
    if (isAuthenticated) {
        if (from.name === 'login') {
            console.log('requireAuth 1' , localStorage.accessToken)
            return next('/');
        } else {
            console.log('requireAuth 2' , localStorage.accessToken)
            return next();
        }
    } else {
        console.log('requireAuth 3' , localStorage.accessToken)
        next('/login');
    }
}
export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "index",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Customer/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "메인"
            }
        },
        {
            path: "/notice/:type",
            name: "notice",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Notice/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "공지/이벤트"
            }
        },
        {
            path: "/customer",
            name: "customer",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Customer/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "고객센터"
            }
        },
        {
            path: "/autoAnswer",
            name: "autoAnswer",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/AutoAnswer/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "답변템플릿"
            }
        },
        {
            path: "/autoAnswer/:id",
            name: "autoAnswerDetail",
            components: {
                header: AppBar,
                default: () => import("./views/AutoAnswer/Detail"),
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "게시판 상세"
            }
        },
        {
            path: "/customer/:id",
            name: "customerDetail",
            components: {
                header: AppBar,
                default: () => import("./views/Customer/Detail"),
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "고객센터 상세"
            }
        },
        {
            path: "/notice/regist",
            name: "noticeRegist",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Notice/Regist.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "공지사항 등록"
            }
        },
        {
            path: "/notice/detail/:id",
            name: "noticeDetail",
            components: {
                header: AppBar,
                default: () => import("./views/Notice/Detail"),
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "공지사항 상세"
            }
        },
        {
            path: "/board",
            name: "board",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Board/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "게시판"
            }
        },
        {
            path: "/board/:id",
            name: "BoardDetail",
            components: {
                header: AppBar,
                default: () => import("./views/Board/Detail"),
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "게시판 상세"
            }
        },
        {
            path: "/member",
            name: "member",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "회원 리스트"
            }
        },
        {
            path: "/memberNew",
            name: "memberNew",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "회원 리스트(신규대기)"
            }
        },
        {
            path: "/member/registExcel",
            name: "memberRegistExcel",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/RegistByExcel.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "회원 등록(엑셀)"
            }
        },
        {
            path: "/memberBetStatus",
            name: "memberBetStatus",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/BetStatus.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "회원별베팅현황"
            }
        },

        {
            path: "/member/detail/:id",
            name: "memberDetail",
            components: {
                // header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/Detail.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "회원 상세"
            }
        },
        {
            path: "/login",
            name: "login",
            components: {
                default: () => import("./views/Member/Login")
            },
            beforeEnter: requireAuthLogin(),
            meta: {
                title: "로그인"
            }
        },
        {
            path: "/member/regist",
            name: "memberRegist",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/Regist.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "회원 등록"
            }
        },
        {
            path: "/setting/site",
            name: "settingSite",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Site/Config.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "사이트설정"
            }
        },

        {
            path: "/setting/global",
            name: "settingGlobal",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Site/Global.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "전역설정"
            }
        },
        
        {
            path: "/setting/password",
            name: "settingPassword",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Site/Password")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "비밀번호설정"
            }
        },
        {
            path: "/setting/game",
            name: "settingGame",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Game/Config.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "게임설정"
            }
        },

        {
            path: "/setting/sports",
            name: "settingSportsOdds",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Odds/Sports.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "스포츠배당설정"
            }
        },

        {
            path: "/setting/live",
            name: "settingLiveOdds",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Odds/Live.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "라이브배당설정"
            }
        },

        {
            path: "/setting/Virtual",
            name: "settingVirtualOdds",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Odds/Virtual.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "가상배당설정"
            }
        },

        {
            path: "/setting/sumratio",
            name: "settingSumRatio",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Odds/Sumratio.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "합배당설정"
            }
        },

        {
            path: "/setting/liveSumratio",
            name: "settingLiveSumRatio",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Odds/LiveSumratio.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "라이브합배당설정"
            }
        },

        {
            path: "/setting/VirtualSumratio",
            name: "settingVirtualSumRatio",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Odds/VirtualSumratio.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "가상합배당설정"
            }
        },

        {
            path: "/setting/blockip",
            name: "settingBlockIp",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/BlockIp/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "차단아이피"
            }
        },

        {
            path: "/setting/popUp",
            name: "settingPopUp",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Site/PopUp.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "팝업설정"
            }
        },

        {
            path: "/setting/Ip",
            name: "settingBlockIp",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/BlockIp/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "아이피설정"
            }
        },
        {
            path: "/setting/OneLineNotice",
            name: "settingOneLineNotice",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/OneLineNotice/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "아이피설정"
            }
        },
        
        {
            path: "/deposit/:id",
            name: "depositList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Deposit/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "충전리스트"
            }
        },
        {
            path: "/withdrawal/:id",
            name: "withdrawalList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Withdrawal/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "환전리스트"
            }
        },

        {
            path: "/teamName/",
            name: "teamNameList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/TeamName/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "팀명관리"
            }
        },
        {
            path: "/setting/bank",
            name: "settingBank",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Bank/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "은행관리"
            }
        },
        {
            path: "/setting/deposit",
            name: "settingDepositAccount",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Deposit/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "입금계좌관리"
            }
        },
        {
            path: "/setting/grade",
            name: "settingGrade",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Grade/Config")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "등급별설정"
            }
        },
        {
            path: "/setting/Sound",
            name: "setttingSound",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Site/Sound.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "알람설정"
            }
        },
        {
            path: "/category/",
            name: "categoryList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Category/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "종목관리"
            }
        },
        {
            path: "/league/",
            name: "leagueList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/League/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "리그관리"
            }
        },
        {
            path: "/market/",
            name: "marketList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Market/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "마켓관리"
            }
        },
        {
            path: "/betHistory/INPLAY",
            name: "inplayBetHistory",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/BetHistory/Inplay/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "베팅내역-인플레이"
            }
        },
        {
            path: "/message/",
            name: "messageList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Message/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "쪽지관리"
            }
        },
        {
            path: "/logOn",
            name: "logOnList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/LogOn.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "현재접속자"
            }
        },
        {
            path: "/BetHistoryPop/:id/:bet",
            name: "BetHistoryPop",
            components: {
                //header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/BetHistory/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "베팅내역팝업"
            }
        },
        {
            path: "/betHistory/:menuCode",
            name: "betHistory",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/BetHistory/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "베팅내역"
            }
        },

        {
            path: "/log/login",
            name: "loginLog",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Login/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "로그인로그"
            }
        },

        {
            path: "/log/Event",
            name: "EventLog",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Event/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "이벤트로그"
            }
        },

        {
            path: "/log/losing",
            name: "logLosing",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Losing/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "루징"
            }
        },
        {
            path: "/log/money",
            name: "moneyLog",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Money/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "머니로그"
            }
        },
        {
            path: "/log/point",
            name: "pointLog",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Point/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "포인트로그"
            }
        },

        {
            path: "/log/rolling",
            name: "rollingLog",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Rolling/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "롤링전환내역"
            }
        },

        {
            path: "/log/getRolling",
            name: "getRollingLog",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Log/Rolling/Get.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "롤링적립내역"
            }
        },


        {
            path: "/popup",
            name: "popupList",
            components: {
                header: AppBar,
                default: () => import("./views/Popup/List"),
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "팝업공지 리스트"
            }
        },
        {
            path: "/popup/detail/:id",
            name: "popupDetail",
            components: {
                header: AppBar,
                default: () => import("./views/Popup/Detail"),
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "팝업공지 상세"
            }
        },
        {
            path: "/popup/regist",
            name: "popupRegist",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Popup/Regist.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "팝업공지 등록"
            }
        },
        {
            path: "/prematch",
            name: "prematchList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Prematch/List")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "프리매치관리"
            }
        },

        {
            path: "/mixBetConfig",
            name: "mixBetConfig",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Setting/Game/MixBetConfig")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "조합베팅설정"
            }
        },

        {
            path: "/prematch/betState",
            name: "prematchBetState",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/BetState/List")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "프리매치-베팅현황"
            }
        },

        {
            path: "/live",
            name: "liveList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Prematch/List")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "라이브관리"
            }
        },

        {
            path: "/inplay",
            name: "inplayList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Inplay/List")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "인플레이관리"
            }
        },

        {
            path: "/casinoGameList",
            name: "casinoGameList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Casino/GameList.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "카지노게임관리"
            }
        },

        {
            path: "/inplay/league",
            name: "inplayLeague",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/League/Inplay/List")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "인플레이-리그관리"
            }
        },

        {
            path: "/agency",
            name: "agency",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Agency/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "에이전시 리스트"
            }
        },
        {
            path: "/agency/regist/:id*",
            name: "agencyRegist",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Agency/Regist.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "대리점 등록"
            }
        },
        {
            path: "/agency/detail/:id*",
            name: "agencyDetail",
            components: {
                //header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Agency/Detail.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "대리점 상세"
            }
        },

        {
            path: "/calculation",
            name: "calculation",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Calculation/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "일별정산"
            }
        },
        {
            path: "/calculation/calendar",
            name: "calculationCalendar",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Calculation/Calendar.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "일별정산(캘린더)"
            }
        },
        {
            path: "/calculation/depthStats",
            name: "depthStats",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Calculation/DepthStats.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "단게별통계"
            }
        },
        {
            path: "/calculation/agency",
            name: "calculationByAgency",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Calculation/AgencyByDepth")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "대리점일별정산"
            }
        },
        {
            path: "/member/point",
            name: "sendPoint",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/Point.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "포인트 지급"
            }
        },

        {
            path: "/powerball/",
            name: "powerballList5",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Powerball/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "EOS파워볼 5분"
            }
        },

        {
            path: "/powerball3/",
            name: "powerballList3",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Powerball/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "EOS파워볼 3분"
            }
        },

        {
            path: "/speedkeno/",
            name: "speedkenoList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Speedkeno/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "스피드키노"
            }
        },
        {
            path: "/virtual/:menuCode",
            name: "virtualGameList",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/VirtualGame/List.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "가상게임"
            }
        },
        {
            path: "/prematch/regist",
            name: "prematchRegist",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Prematch/Regist")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "게임등록"
            }
        },
        {
            path: "/prematch/registByExcel",
            name: "prematchRegistByExcel",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Sport/Prematch/RegistByExcel")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "게임등록(엑셀)"
            }
        },
        {
            path: "/attendance",
            name: "attendance",
            components: {
                header: AppBar,
                // right:RightLayout,
                // bottom:BottomLayout,
                default: () => import("./views/Member/Attendance.vue")
            },
            beforeEnter: requireAuth(),
            meta: {
                title: "출석체크"
            }
        },
        // {
        //   path: "/sports",
        //   name: "sports",
        //   components: {
        //     header: TopBar,
        //     right:RightLayout,
        //     bottom:BottomLayout,
        //     default: () => import("./views/Sports.vue"),
        //     // true로 설정하면 데이터를 meta로도 받습니다.
        //     // meta: true,
        //   },
        //   meta: {
        //     title: "스포츠"
        //   }
        // },

    ]


});



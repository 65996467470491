<template>
  <v-app id="app">
    <router-view name="header"></router-view>
    <v-main class="customBg justify_center">
      <div class="pt-1 mainWrap"
           :class="$vuetify.breakpoint.mdAndUp ? 'px-1': ''"
      >
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>

</template>

<script>

const url = 'https://api.ipify.org?format=json'

import '@/styles/nao.css'
import '@/styles/default.css'
import '@/styles/editor.scss'
import '@/styles/common.css'
import '@/styles/contents.css'

import axios from "axios";

export default {

  data: () => ({
    results: []
  }),

  methods: {


    // getConfigData: async function () {
    //
    //   await axios.get(url).then(response => {
    //     this.results = response.data
    //   })
    //
    //   await axios
    //       .get(`/admin/setting/detailed`,
    //           {
    //             params: {},
    //           })
    //       .then(({data}) => {
    //         this.configDetail = data;
    //
    //         let ipArray = this.configDetail.adminIp.split(",")
    //
    //         if (ipArray.length > 0) {
    //           if (ipArray.indexOf(this.results.ip) === -1) {
    //             location.href = "https://naver.com";
    //           } else {
    //             console.log("granted ip")
    //           }
    //
    //         }
    //         console.log("allow all ip")
    //
    //       })
    //       .catch(async ex => {
    //         if (ex.response.status === 401) {
    //           if (await this.$refreshToken()) {
    //
    //           }
    //         } else if (ex.response.status === 403) {
    //           this.$logout();
    //         }
    //       })
    // },

  },

  mounted() {
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        /*this.getConfigData();*/
        document.title = to.meta.title || 'Some Default Title';
      }
    }
  }
}
</script>
<style>
#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
   height: 8px;

}

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.menuCode === 'SPORTS_GAME' ||
        _vm.menuCode === 'MATCH' ||
        _vm.menuCode === 'CROSS' ||
        _vm.menuCode === 'HANDICAP' ||
        _vm.menuCode === 'LIVE' ||
        _vm.menuCode === 'SPECIAL' ||
        _vm.menuCode === 'VSOCCER' ||
        _vm.menuCode === 'VBASKETBALL')?_c('sports',{attrs:{"myBetList":_vm.bettingList,"menuCode":_vm.menuCode},on:{"cancelBetItem":_vm.cancelBetItem}}):(_vm.menuCode === 'INPLAY')?_c('inplay',{attrs:{"myBetList":_vm.bettingList,"menuCode":_vm.menuCode},on:{"cancelBetItem":_vm.cancelBetItem,"openResetBetItem":_vm.openResetBetItem}}):(_vm.menuCode === 'POWERBALL5M' || _vm.menuCode === 'POWERBALL3M' || _vm.menuCode ==='SPEEDKENO')?_c('power-ball',{attrs:{"myBetList":_vm.bettingList},on:{"cancelBetItem":_vm.cancelBetItem}}):(_vm.menuCode === 'VDOGRACING')?_c('v-dog-racing',{attrs:{"myBetList":_vm.bettingList},on:{"cancelBetItem":_vm.cancelBetItem}}):(_vm.menuCode === 'CASINO_LIVE' || _vm.menuCode === 'CASINO_SLOT')?_c('casino',{attrs:{"myBetList":_vm.bettingList},on:{"cancelBetItem":_vm.cancelBetItem}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>

    <!--당첨-->
    <v-card
        elevation="24"
    >
      <v-simple-table
          style="background-color: rgba(245, 241, 238, 0.08)"
      >
        <tbody
            v-for="(betItem,i) in myBetList"
            :key="i">
        <tr class="game-info">
          <td colspan="5" class="text-left">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <div class="pr-2">
                  [{{ betItem.gameCodeText }}]
                </div>
                <div>
                  {{ betItem.league }}
                </div>

              </div>
              <div>
                <div>
                  {{ betItem.matchedAt }}
                  <v-btn
                      tile
                      color="black"
                      small
                      class=""
                      @click="cancelBetItem(betItem.betItemId)"
                      disabled
                  >관리자취소
                  </v-btn>
                </div>
              </div>
            </div>
          </td>

        </tr>
        <tr
            class="game-info">
          <td
              class="text-center "
              :class="betItem.whichBet === 'DOG_1' ? 'betSelected font-weight-bold': ''">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{ betItem.teamHome }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsHome }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'DOG_2' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{ betItem.team3 }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsDraw }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'DOG_3' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{ betItem.teamAway }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsAway }}
              </div>
            </div>
          </td>

          <td rowspan="2"
              colspan="2"
              class="text-center" >
            <div>
              {{ betItem.statusValue }}
            </div>
            <div>
              <v-icon color="success" small v-if="betItem.status==='WIN'">mdi-circle-outline</v-icon>
              <v-icon color="error" small v-else-if="betItem.status==='LOSE'">mdi-close</v-icon>
              <v-icon color="white" small v-else-if="betItem.status==='DENY' || betItem.status.includes('CANCEL')">mdi-cancel</v-icon>
              <v-icon color="white" small v-else-if="betItem.status==='READY'">mdi-timer-sand</v-icon>
              <v-icon color="primary" small v-else-if="betItem.status==='INVALID'">mdi-triangle-outline</v-icon>
            </div>
          </td>
        </tr>
        <tr>
          <td
              class="text-center "
              :class="betItem.whichBet === 'DOG_4' ? 'betSelected font-weight-bold': ''">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{ betItem.team4 }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsExtra1 }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'DOG_5' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{ betItem.team5 }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsExtra2 }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'DOG_6' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{ betItem.team6 }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsExtra3 }}
              </div>
            </div>
          </td>
        </tr>

        </tbody>
        <tr class="win">
          <td colspan="5" class="text-right pl-3">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center">
                <div class="text-center">
                  <v-icon class="font-15">mdi-timer-outline</v-icon>
                  {{ myBetList.betAt }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-around">

                <div class="">
                  <v-chip
                      class="ma-2"
                      label
                      :color="myBetList.status === 'WON' ? 'success' : (myBetList.status === 'NOT_WON' ? 'error' : 'primary' )"
                      text-color="white"
                      style="pointer-events: none"
                  >
                    {{ myBetList.statusValue }}
                  </v-chip>
                </div>
              </div>
            </div>
          </td>
        </tr>

      </v-simple-table>
    </v-card>
    <v-divider
        class="mt-4"
        dark
    ></v-divider>
  </div>

</template>

<script>
export default {
  name: "SportsMobile",
  props: {
    myBetList: {
      type: Array
    },

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },


  }
}
</script>

<style scoped>

</style>
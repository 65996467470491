<template>
  <div class="">
    <sports
        v-if="menuCode === 'SPORTS_GAME' ||
          menuCode === 'MATCH' ||
          menuCode === 'CROSS' ||
          menuCode === 'HANDICAP' ||
          menuCode === 'CROSS' ||
          menuCode === 'SPECIAL' ||
          menuCode === 'VSOCCER' ||
          menuCode === 'VBASKETBALL'"
        :myBetList="bettingList"
        :menuCode="menuCode"
        v-on:cancelBetItem="cancelBetItem"
    >
    </sports>
    <power-ball
        v-else-if="menuCode.includes('POWERBALL') || menuCode === 'SPEEDKENO'"
        :myBetList="bettingList"
        v-on:cancelBetItem="cancelBetItem"
    >
    </power-ball>
    <v-dog-racing
        v-else-if="menuCode === 'VDOGRACING'"
        :myBetList="bettingList"
        v-on:cancelBetItem="cancelBetItem"
    >
    </v-dog-racing>


  </div>
</template>

<script>
import Sports from "@/components/Sport/BetHistory/GameType/Sports/Mobile";
import PowerBall from "@/components/Sport/BetHistory/GameType/PowerBall/Mobile";
import VDogRacing from "@/components/Sport/BetHistory/GameType/DogRacing/Mobile";
export default {
  name: "BoardMobile",
  data() {
    return {
      bettingList: [],
    }
  },
  props: {
    myBetList: {
      type: Array
    },
    menuCode: {
      type: String
    }
  },
  components: {
    Sports,
    PowerBall,
    VDogRacing
  },
  methods: {
    openDetail: function (list) {
      this.bettingList = list
      console.log('betlist', this.bettingList)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
  }
}
</script>

<style scoped>


button {
  font-size: 12px !important;
}


.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 5px;
  color: #f2f2f2 !important;
}


</style>
<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        clipped
        app
        ref="navigationDrawer"
        width="183"
    >
      <v-sheet
          color="grey darken-4"
          class="pa-0 customBg"
          ref="navigationDrawerTitle"
      >
        <!--        <div class="logoTitle"></div>-->
        <div class="px-2 font-11 font-weight-bold">
          <div class="d-flex justify-space-between font-orange">
            <div class="">
              Swix
            </div>
            <div>
              {{ casinoBalance | comma }} 알
            </div>
          </div>
          <div class="d-flex justify-space-between font-red">
            <div>
              관심회원베팅
            </div>
            <div v-if="Object.keys(dashBoardItem).length >0">
              <span class="" style="cursor:pointer" @click="$move('/betHistory/SPORTS_GAME')">{{
                  dashBoardItem.betInfo.preMatchAttentionBets | comma
                }}</span> /
              <span class="" style="cursor:pointer" @click="$move('/betHistory/LIVE')">{{
                  dashBoardItem.betInfo.liveAttentionBets | comma
                }}</span> /
              <!--              <span class="" style="cursor:pointer"
                                  @click="$move('/betHistory/INPLAY')">{{ dashBoardItem.betInfo.inplayAttentionBets | comma }}</span>
                            /-->
              <span class="" style="cursor:pointer"
                    @click="$move('/betHistory/CASINO')">{{ dashBoardItem.betInfo.casinoAttentionBets | comma }}</span>
              /
              <span class="" style="cursor:pointer" @click="$move('/betHistory/VIRTUAL_GAME')">{{
                  dashBoardItem.betInfo.virtualAttentionBets | comma
                }}</span> /
              <span class="" style="cursor:pointer" @click="$move('/betHistory/MINI_GAME')">{{
                  dashBoardItem.betInfo.miniGameAttentionBets | comma
                }}</span>
            </div>
          </div>
        </div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list
          class="menuList font-13"
          ref="navigationMenu"
      >
        <perfect-scrollbar
            v-resize="onResize"
            :style="scrollerStyle"
        >

          <v-list-group
              v-for="(item, i) in menuItems"
              :key="i"
              no-action
              sub-group
              prepend-icon=""
              style="box-shadow: 1px 0px 2px rgba(33,33,33,0.6);"
          >

            <template v-slot:activator>
              <v-icon class="mr-2" style="color:rgba(255,255,255,0.12);font-size:18px">{{ menuItems[i].icon }}</v-icon>
              <v-list-item-content>
                <v-list-item-title
                    link
                    :to="item.path"
                    v-text="item.title"
                    class="appBar"
                    style="font-weight:600 !important;font-size:11px !important;"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="child in item.items"
                :key="child.title"
                link
                exact-path
                :to="child.path"
            >
              <v-list-item-content
                  @click="checkMenuTab(child.path)">
                <v-list-item-title
                    class="appBar menuChild"
                    link
                    v-text="child.title"
                    style="font-size:11px !important;"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

        </perfect-scrollbar>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        id="default-app-bar"
        app
        fixed
        dense
        class="appBar customBg"
        color="transparent"
        clipped-left
        :height="$vuetify.breakpoint.mdAndUp? 140 : ''"
        flat
    >
      <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
      />
      <div class="hidden-sm-and-down">
        <v-btn
            class=""
            elevation="1"
            text
            width="85"
            @click="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}
          </v-icon>
        </v-btn>
      </div>
      <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center w-100"
      >
        <notifications
            :dashBoardItem="dashBoardItem"
        />
        <status-board-pc
            :dashBoardItem="dashBoardItem"
            :soundCharge="soundCharge"
            :soundExchange="soundExchange"
            :soundCustomer="soundCustomer"
            :soundMember="soundMember"
            :overlay="overlay"
            :soundAttentionMember="soundAttentionMember"
            v-on:move="move"
            ref="status"
        />
        <v-spacer/>

      </div>
      <div
          v-else
          class="d-flex align-center w-100"
      >
        <v-spacer/>
        <notifications
            :dashBoardItem="dashBoardItem"
        />
        <status-board-mobile
            :dashBoardItem="dashBoardItem"
            :soundCharge="soundCharge"
            :soundExchange="soundExchange"
            :soundCustomer="soundCustomer"
            :soundMember="soundMember"
            :overlay="overlay"
            v-on:move="move"
            ref="status"
        />
      </div>

    </v-app-bar>
    <alert-dialog
        :dialog.sync=dialog.alert
        :text=dialog.alertText
        v-on:alertAction="alertAction"
    ></alert-dialog>
    <!--    <v-navigation-drawer-->
    <!--        v-model="drawer"-->
    <!--        clipped-->
    <!--        absolute-->
    <!--        app-->
    <!--        dark-->
    <!--        ref="navigationDrawer"-->
    <!--        class="naviDrawer"-->
    <!--        width="186"-->
    <!--    >-->
    <!--      <v-sheet-->
    <!--          color="grey darken-4"-->
    <!--          class="pa-0 customBg"-->
    <!--          ref="navigationDrawerTitle"-->
    <!--      >-->
    <!--        <div class="px-2 py-3 font-13 font-weight-bold">-->
    <!--          <div class="d-flex justify-space-between font-orange">-->
    <!--            <div class="">-->
    <!--              Swix-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0 알-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between green&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              금일 입금-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between red&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              금일 출금-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between green&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              금일 베팅-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between red&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              금일 당첨-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between blue&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              유저금액-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between blue&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              유저포인트-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between blue&#45;&#45;text">-->
    <!--            <div class="">-->
    <!--              유저롤링-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between font-orange">-->
    <!--            <div class="">-->
    <!--              총판보유금액-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between font-orange">-->
    <!--            <div class="">-->
    <!--              총판포인트-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between font-orange">-->
    <!--            <div class="">-->
    <!--              금일 총판루징-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="d-flex justify-space-between font-orange">-->
    <!--            <div class="">-->
    <!--              금일 총판롤링-->
    <!--            </div>-->
    <!--            <div>-->
    <!--              0-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-sheet>-->

    <!--      <v-divider></v-divider>-->

    <!--      <v-list-->
    <!--          class="menuList font-13"-->
    <!--          ref="navigationMenu"-->
    <!--      >-->


    <!--          <v-list-group-->
    <!--              v-for="(item, i) in menuItems"-->
    <!--              :key="i"-->
    <!--              no-action-->
    <!--              sub-group-->
    <!--              prepend-icon=""-->
    <!--              style="box-shadow: 1px 0px 2px rgba(33,33,33,0.6);"-->
    <!--          >-->

    <!--            <template v-slot:activator>-->
    <!--              <v-icon class="mr-2" style="color:rgba(255,255,255,0.12);font-size:18px">{{ menuItems[i].icon }}</v-icon>-->
    <!--              <v-list-item-content>-->
    <!--                <v-list-item-title-->
    <!--                    link-->
    <!--                    :to="item.path"-->
    <!--                    v-text="item.title"-->
    <!--                    class="appBar"-->
    <!--                    style="font-weight:600 !important;font-size:11px !important;"-->
    <!--                ></v-list-item-title>-->
    <!--              </v-list-item-content>-->
    <!--            </template>-->

    <!--            <v-list-item-->
    <!--                v-for="child in item.items"-->
    <!--                :key="child.title"-->
    <!--                link-->
    <!--                exact-path-->
    <!--                :to="child.path"-->
    <!--            >-->
    <!--              <v-list-item-content-->
    <!--                  @click="checkMenuTab(child.path)">-->
    <!--                <v-list-item-title-->
    <!--                    class="appBar menuChild"-->
    <!--                    link-->
    <!--                    v-text="child.title"-->
    <!--                    style="font-size:11px !important;"-->
    <!--                ></v-list-item-title>-->
    <!--              </v-list-item-content>-->
    <!--            </v-list-item>-->
    <!--          </v-list-group>-->

    <!--      </v-list>-->
    <!--    </v-navigation-drawer>-->

    <!--    <v-app-bar-->
    <!--        id="default-app-bar"-->

    <!--        dark-->
    <!--        min-width="1600"-->
    <!--        class="v-bar&#45;&#45;underline customBg px-0"-->
    <!--        color="transparent"-->
    <!--        clipped-left-->
    <!--        :height="$vuetify.breakpoint.mdAndUp? 100 : ''"-->
    <!--        flat-->
    <!--    >-->
    <!--      <v-app-bar-nav-icon-->
    <!--          class="hidden-md-and-up"-->
    <!--          @click="drawer = !drawer"-->
    <!--      />-->

    <!--      <div-->
    <!--          v-if="$vuetify.breakpoint.mdAndUp"-->
    <!--          class="d-flex align-center  w-100"-->
    <!--      >-->
    <!--        <v-simple-table-->
    <!--            dense-->
    <!--            class="w-100"-->
    <!--        >-->
    <!--          <template v-slot:default>-->
    <!--            <thead>-->
    <!--            <tr>-->
    <!--              <th class="text-center font-weight-bold font-15 text-center border-all" width="186" @click="move('/')">-->
    <!--                ADMIN-->
    <!--              </th>-->
    <!--              <th class="text-center" width="60">-->
    <!--                <v-btn-->
    <!--                    class="ml-3 mr-4"-->
    <!--                    elevation="1"-->
    <!--                    fab-->
    <!--                    small-->
    <!--                    @click="drawer = !drawer"-->
    <!--                >-->
    <!--                  <v-icon>-->
    <!--                    {{ drawer ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}-->
    <!--                  </v-icon>-->
    <!--                </v-btn>-->
    <!--              </th>-->
    <!--              <th class="text-center pa-0">-->
    <!--                <status-board-pc-->
    <!--                    :dashBoardItem="dashBoardItem"-->
    <!--                    :soundCharge="soundCharge"-->
    <!--                    :soundExchange="soundExchange"-->
    <!--                    :soundCustomer="soundCustomer"-->
    <!--                    :soundMember="soundMember"-->
    <!--                    v-on:move="move"-->
    <!--                    ref="status"-->
    <!--                />-->
    <!--              </th>-->
    <!--            </tr>-->
    <!--            </thead>-->
    <!--          </template>-->
    <!--        </v-simple-table>-->
    <!--        &lt;!&ndash;        <div :style="{'min-width':'250px'}" class="d-flex  align-center">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class="font-weight-bold font-15 text-center border-all" :style="{'min-width':'186px'}">&ndash;&gt;-->
    <!--        &lt;!&ndash;            ADMIN&ndash;&gt;-->
    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class="hidden-sm-and-down">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <v-btn&ndash;&gt;-->
    <!--        &lt;!&ndash;                class="ml-3 mr-4"&ndash;&gt;-->
    <!--        &lt;!&ndash;                elevation="1"&ndash;&gt;-->
    <!--        &lt;!&ndash;                fab&ndash;&gt;-->
    <!--        &lt;!&ndash;                small&ndash;&gt;-->
    <!--        &lt;!&ndash;                @click="drawer = !drawer"&ndash;&gt;-->
    <!--        &lt;!&ndash;            >&ndash;&gt;-->
    <!--        &lt;!&ndash;              <v-icon>&ndash;&gt;-->
    <!--        &lt;!&ndash;                {{ drawer ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}&ndash;&gt;-->
    <!--        &lt;!&ndash;              </v-icon>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </v-btn>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->

    <!--        &lt;!&ndash;&lt;!&ndash;        <notifications&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;&lt;!&ndash;            :dashBoardItem="dashBoardItem"&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;&lt;!&ndash;        />&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;        <status-board-pc&ndash;&gt;-->
    <!--        &lt;!&ndash;            :dashBoardItem="dashBoardItem"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundCharge="soundCharge"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundExchange="soundExchange"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundCustomer="soundCustomer"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundMember="soundMember"&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-on:move="move"&ndash;&gt;-->
    <!--        &lt;!&ndash;            ref="status"&ndash;&gt;-->
    <!--        &lt;!&ndash;        />&ndash;&gt;-->
    <!--        &lt;!&ndash;        <v-spacer />&ndash;&gt;-->

    <!--        &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;      <div&ndash;&gt;-->
    <!--        &lt;!&ndash;          v-else&ndash;&gt;-->
    <!--        &lt;!&ndash;          class="d-flex align-center w-100"&ndash;&gt;-->
    <!--        &lt;!&ndash;      >&ndash;&gt;-->
    <!--        &lt;!&ndash;        <v-spacer />&ndash;&gt;-->
    <!--        &lt;!&ndash;        <notifications&ndash;&gt;-->
    <!--        &lt;!&ndash;            :dashBoardItem="dashBoardItem"&ndash;&gt;-->
    <!--        &lt;!&ndash;        />&ndash;&gt;-->
    <!--        &lt;!&ndash;        <status-board-mobile&ndash;&gt;-->
    <!--        &lt;!&ndash;            :dashBoardItem="dashBoardItem"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundCharge="soundCharge"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundExchange="soundExchange"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundCustomer="soundCustomer"&ndash;&gt;-->
    <!--        &lt;!&ndash;            :soundMember="soundMember"&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-on:move="move"&ndash;&gt;-->
    <!--        &lt;!&ndash;            ref="status"&ndash;&gt;-->
    <!--        &lt;!&ndash;        />&ndash;&gt;-->
    <!--      </div>-->

    <!--    </v-app-bar>-->
  </div>

</template>

<script>


// import axios from 'axios'
import StatusBoardPc from "@/components/_Default/StatusBoard/Pc";
import StatusBoardMobile from "@/components/_Default/StatusBoard/Mobile";
import Notifications from "@/components/_Default/Notifications";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import {eventBus} from '@/plugins/eventBus'

export default {
  name: "AppBar",
  data: () => ({
    interval: false,
    socketInterval: false,
    drawer: false,
    boardLoadingFlag: true,
    overlay: true,
    boardList: Object,
    navigationHeight: 0,
    dashBoardItem: {},
    soundCharge: '',
    soundExchange: '',
    soundCustomer: '',
    soundMember: '',
    soundAttentionMember: '',
    menuItems: [
      /*{icon:'mdi-home',title:'메인페이지',path:'/'},*/

      {
        icon: 'mdi-face-agent',
        title: '고객센터',
        path: '/Customer',
        items: [
          {title: '문의리스트', path: '/customer'},
          {title: '답변템플릿', path: '/autoAnswer'}
        ]
      },
      {
        icon: 'mdi-currency-krw',
        title: '충환전관리',
        path: '/Customer',
        items: [
          {title: '충전리스트', path: '/deposit/All'},
          {title: '환전리스트', path: '/withdrawal/All'},
        ]
      },
      {
        icon: 'mdi-trophy',
        title: '프리매치/라이브',
        path: '/',
        items: [
          {title: '베팅내역(프리매치)', path: '/betHistory/SPORTS_GAME'},
          {title: '베팅내역(라이브)', path: '/betHistory/LIVE'},
          {title: '베팅현황(프리매치)', path: '/prematch/betState'},
          {title: '프리매치관리', path: '/prematch'},
          {title: '라이브관리', path: '/live'},
          {title: '게임등록', path: '/prematch/regist'},
          {title: '게임등록(엑셀)', path: '/prematch/registByExcel'},
          {title: '종목관리', path: '/category'},
          {title: '팀명관리', path: '/teamName'},
          {title: '리그관리', path: '/league'},
          {title: '프리매치배당설정', path: '/setting/sports'},
          {title: '라이브배당설정', path: '/setting/live'},
          {title: '조합베팅설정', path: '/mixBetConfig'},
        ]
      },
      {
        icon: 'mdi-trophy',
        title: '인플레이',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/INPLAY'},
          {title: '인플레이관리', path: '/inplay'},
          {title: '리그관리', path: '/inplay/league'},
          {title: '마켓관리', path: '/market'},
        ]
      },
      {
        icon: 'mdi-trophy',
        title: '미니게임',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/MINI_GAME'},
          {title: '파워볼5분', path: '/powerball/'},
          {title: '파워볼3분', path: '/powerball3/'},
          {title: '스피드키노', path: '/speedkeno'},

        ]
      },
      {
        icon: 'mdi-trophy',
        title: '가상게임',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/VIRTUAL_GAME'},
          {title: '가상축구', path: '/virtual/VSOCCER'},
          {title: '가상농구', path: '/virtual/VBASKETBALL'},
          {title: '개경주', path: '/virtual/VDOGRACING'},
          {title: '배당설정', path: '/setting/virtual'},

          /*{title: '베팅내역(축구)', path: '/betHistory/VSOCCER'},
          {title: '베팅내역(농구)', path: '/betHistory/VBASKETBALL'},
          {title: '베팅내역(개경주)', path: '/betHistory/VDOGRACING'},*/
        ]
      },

      {
        icon: 'mdi-cards-spade',
        title: '카지노',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/CASINO'},
          {title: '게임관리(스윅스)', path: '/casinoGameList'},
        ]
      },
      {
        icon: 'mdi-clipboard', title: '공지/게시판/이벤트', path: '/board',
        items: [
          {title: '공지사항', path: '/notice/NOTICE'},
          {title: '게시판', path: '/board'},
          {title: '이벤트', path: '/notice/EVENT'},
          {title: '팝업공지', path: '/notice/POPUP'},
        ]
      },
      {
        icon: 'mdi-account', title: '회원', path: '/member',
        items: [
          {title: '회원목록', path: '/member'},
          {title: '회원등록', path: '/member/regist'},
          {title: '회원등록(엑셀)', path: '/member/registExcel'},
          {title: '회원별베팅', path: '/memberBetStatus'},
          {title: '쪽지관리', path: '/message/'},
          {title: '포인트지급', path: '/member/point'},
          {title: '현재접속자', path: '/logOn'},
          {title: '출석체크', path: '/attendance'},
        ]
      },
      {
        icon: 'mdi-calculator', title: '정산', path: '/calculation',
        items: [
          {title: '일별정산', path: '/calculation'},
          /*{title: '월간정산(캘린더)', path: '/calculation/calendar'},*/
          {title: '파트너별정산', path: '/calculation/agency'},
          {title: '단계별통계', path: '/calculation/depthStats'},
        ]
      },
      {
        icon: 'mdi-source-branch', title: '파트너', path: '/agency',
        items: [
          {title: '파트너목록', path: '/agency'},
        ]
      },
      {
        icon: 'mdi-cog-outline', title: '설정', path: '/setting',
        items: [
          {title: '사이트 설정', path: '/setting/site'},
          /*{title: '전역 설정', path: '/setting/global'},*/
          {title: '게임/베팅 설정', path: '/setting/game'},
          {title: 'IP 설정', path: '/setting/Ip'},
          /*{title: '한줄 공지', path: '/setting/OneLineNotice'},*/
          {title: '은행 설정', path: '/setting/bank'},
          {title: '계좌 설정', path: '/setting/deposit'},
          {title: '알람음 설정', path: '/setting/Sound'},
          {title: '등급별 설정', path: '/setting/grade'},
          {title: '배당설정', path: '/setting/sports'},
          /*{title: '입금계좌', path: '/setting/deposit'},*/
          // {title: '차단IP관리', path: '/setting/blockip'},
          // {title: '은행관리', path: '/setting/bank'},
        ]
      },
      {
        icon: 'mdi-history', title: '로그', path: '/log/login',
        items: [
          {title: '로그인내역', path: '/log/login'},
          {title: '머니내역', path: '/log/money'},
          {title: '포인트내역', path: '/log/point'},
          {title: '롤링적립', path: '/log/getRolling'},
          {title: '롤링전환', path: '/log/rolling'},
          {title: '루징내역', path: '/log/losing'},
          {title: '이벤트로그', path: '/log/Event'},
        ]
      },
    ],
    currPath: '',
    casinoBalance: 0,
    dialog: {
      alert: false,
      alertText: '',
      alertOption: {
        persistent: false
      },
      alertActionType: '',
    },
    alarmList: [],
    socket: null,
    stompClient: null,
    subscriptionId:'',
    connected: false,
  }),
  components: {
    StatusBoardPc,
    StatusBoardMobile,
    Notifications
  },

  methods: {

    alertAction: function () {
      this.dialog.alert = !this.dialog.alert
      if(this.dialog.alertActionType === 'logout') {
        this.$logout()
      }
    },

    setMenuTab: function () {
      this.currPath = this.$route.path
    },
    move: function (targetPath) {
      console.log(targetPath, this.currPath)
      if (targetPath === this.currPath) {
        eventBus.getData()
      } else {
        this.$router.push({path: targetPath})
      }

    },


    checkMenuTab: function (targetPath) {
      console.log('checkMenuTab!!!')
      console.log(targetPath, this.currPath)
      if (targetPath === this.currPath) {
        eventBus.getData()
      } else {
        // this.$router.push({ path: targetPath })
      }
    },

    getCasinoBalance: async function () {
      await axios
          .post(`/admin/game/casino/swix/info/balance`,
              {
                params: {},
              })
          .then(({data}) => {
            this.casinoBalance = data.r;
            // console.log('보유알',data)
          })
          .catch(async ex => {
            console.log('err!!',ex)
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getCasinoBalance()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true

                }
                // this.dialog.alert = true;
                // this.dialog.alertText = refreshTokenResult.msg
                // if (refreshTokenResult.msg !== '') {
                //   this.dialog.alertText = refreshTokenResult.msg
                // } else {
                //   this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                // }

              }
           } else if (ex.response.status === 403) {
              // this.dialog.alert = true;
              // if (ex.response.data !== '') {
              //   this.dialog.alertText = ex.response.data.message
              // } else {
              //   this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              // }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })
    },

    getAlarmList: function () {
      axios
          .get(`/admin/site/alarms`,
              {
                params: {},
              })
          .then(({data}) => {
            this.alarmList = data;
            console.log('alarmList', this.alarmList)
          })
          .catch(async ex => {
            console.log(ex)
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getAlarmList()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                }
                this.dialog.alert = true;
                if (refreshTokenResult.msg !== '') {
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                }

              }
           } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              if (ex.response.data !== '') {
                this.dialog.alertText = ex.response.data.message
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })
    },

    getData: async function () {
      console.log(33)
      if (this.boardLoadingFlag) {
        console.log(44)
        this.boardLoadingFlag = false
        await axios
            .get(`/admin/dashboards`,
                {
                  params: {},
                })
            .then(({data}) => {
              console.log(data)
              this.dashBoardItem = data;

              //this.getCasinoBalance()
              this.overlay = false

              console.log('betInfo',this.dashBoardItem.betInfo)

              if ( this.dashBoardItem.betInfo.sportsAttentionBets > 0 ||
                  this.dashBoardItem.betInfo.casinoAttentionBets > 0 ||
                  this.dashBoardItem.betInfo.virtualAttentionBets > 0 ||
                  this.dashBoardItem.betInfo.miniGameAttentionBets > 0
              ) {
                this.soundAttentionMember = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[4].fullUrl + '\' style=\'width:1px;height:1px\'/>'
              } else {
                this.soundAttentionMember = ''
              }

              if(this.dashBoardItem.chargeInfo.requestedCount > 0 && this.soundCharge === ''){
                this.soundCharge = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[2].fullUrl + '\' style=\'width:1px;height:1px\'/>'
              }

              if(this.dashBoardItem.exchangeInfo.requestedCount > 0 && this.soundExchange === ''){
                this.soundExchange = '<audio autoplay=\'true\' loop=\'true\'  src=\'' + this.alarmList[2].fullUrl + '\'  style=\'width:1px;height:1px\'/>'
              }

              if(this.dashBoardItem.customerCenterInfo.newCount > 0 && this.soundCustomer === ''){
                this.soundCustomer = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[3].fullUrl + '\' style=\'width:1px;height:1px\'/>'
              }

              if(this.dashBoardItem.memberInfo.newCount > 0 && this.soundMember === ''){
                this.soundMember = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[0].fullUrl + '\' style=\'width:1px;height:1px\'/>'
              }
            })
            .catch(async ex => {
              console.log('err!!',ex)
              if (ex.response.status === 401) {
                let refreshTokenResult = await this.$refreshToken()
                console.log(refreshTokenResult)
                if (refreshTokenResult.flag) {
                  await this.getData()
                } else {
                  if (refreshTokenResult.status === 403) {
                    this.dialog.alertActionType = 'logout'
                    this.dialog.alertOption.persistent = true
                    clearInterval(this.interval)
                    clearInterval(this.socketInterval)
                  }
                  this.dialog.alert = true;
                  this.dialog.alertText = refreshTokenResult.msg
                  if (refreshTokenResult.msg !== '') {
                    this.dialog.alertText = refreshTokenResult.msg
                  } else {
                    this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                  }

                }
              } else if (ex.response.status === 403) {
                this.dialog.alert = true;
                if (ex.response.data !== '') {
                  this.dialog.alertText = ex.response.data.message
                } else {
                  this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                }

                this.dialog.alertActionType = 'logout'
                this.dialog.alertOption.persistent = true
                clearInterval(this.interval)
                clearInterval(this.socketInterval)
              } else {
                this.dialog.alert = true;
                this.dialog.alertText = ex.response.data.message
              }

            })
        this.boardLoadingFlag = true
      }

    },

    upDateStatusBoard: function (data) {

      switch (Object.keys(data)[0]) {
        case 'chargeInfo':
          this.dashBoardItem.chargeInfo = data.chargeInfo
          if (this.dashBoardItem.chargeInfo.requestedCount > 0) {
            this.soundCharge = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[1].fullUrl + '\' style=\'width:1px;height:1px\'/>'
          } else {
            this.soundCharge = ''
          }
          break;
        case 'exchangeInfo':
          this.dashBoardItem.exchangeInfo = data.exchangeInfo
          if (this.dashBoardItem.exchangeInfo.requestedCount > 0) {
            this.soundExchange = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[2].fullUrl + '\' style=\'width:1px;height:1px\'/>'
          } else {
            this.soundExchange = ''
          }
          break;
        case 'customerCenterInfo':
          this.dashBoardItem.customerCenterInfo = data.customerCenterInfo
          if (this.dashBoardItem.customerCenterInfo.newCount > 0) {
            this.soundCustomer = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[3].fullUrl + '\' style=\'width:1px;height:1px\'/>'
          } else {
            this.soundCustomer = ''
          }
          break;
        case 'memberInfo':
          this.dashBoardItem.memberInfo.newCount = data.memberInfo.newCount
          this.dashBoardItem.memberInfo.readyCount = data.memberInfo.readyCount
          if (this.dashBoardItem.memberInfo.newCount > 0) {
            this.soundMember = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[0].fullUrl + '\' style=\'width:1px;height:1px\'/>'
          } else {
            this.soundMember = ''
          }
          break;
          /*case 'attentionMemberBetInfo':
            this.dialog.alertText = data.attentionMemberBetInfo.message
            this.dialog.alert = true;
            if (this.dashBoardItem.memberInfo.attentionMemberBetInfo > 0) {
              this.soundAttentionMember = '<audio autoplay=\'true\' loop=\'true\' src=\'' + this.alarmList[4].fullUrl + '\' style=\'width:1px;height:1px\'/>'
            } else {
              this.soundAttentionMember = ''
            }
            break;*/
        default :
          break;
      }

    },
    subScribeInplay: function () {
      this.stompClient.subscribe("/topic/dashboard", async tick => {
        this.subscriptionId = tick.headers.subscription
        await this.upDateStatusBoard(JSON.parse(tick.body));
      });
    },
    async connect() {
      this.socket = new SockJS(process.env.VUE_APP_WS_URL);
      this.stompClient = Stomp.over(this.socket);
      // this.stompClient = this.$initSocket()
      this.stompClient.connect(
          {},
          frame => {
            this.connected = true;
            this.subScribeInplay()

          },
          error => {
            console.log(error);
            this.connected = false;
          }
      );
    },
    disconnect() {
      this.stompClient.unsubscribe(this.subscriptionId)
      this.stompClient.disconnect()
      this.$socketDisconnect()
      this.connected = false;
      clearInterval(this.socketInterval)
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },

    setDrawer: function () {
      this.drawer = this.$vuetify.breakpoint.mdAndUp;
    },
    onResize: async function () {
      await this.$nextTick();
      console.log(this.$refs.navigationDrawer)
      console.log(this.$refs.navigationDrawerTitle)
      this.navigationHeight = this.$refs.navigationDrawer.$el.clientHeight - (this.$refs.navigationDrawerTitle.$el.clientHeight + 40);
    },
    socketCheck: function () {
      if(!this.stompClient.connected) {
        this.connect();
      }
    }
  },
  mounted() {

    // this.onResize()
    this.setDrawer()
    this.getData();
    this.getAlarmList();

    this.setMenuTab()
  },

  created() {
    this.interval = setInterval(this.getData.bind(this), 10000);
    if(this.connected !== true){
      this.connect();
    }
    this.socketInterval = setInterval(this.socketCheck.bind(this), 3000);
  },
  beforeDestroy() {
    this.disconnect()
  },
  destroyed() {
    clearInterval(this.interval)
    clearInterval(this.socketInterval)
  },


  computed: {
    scrollerStyle() {
      return {
        height: `${this.navigationHeight}px`,
      }

    },
  },
  watch: {
    $route(to, from) {
      // console.log(to,from)
      this.getData()
      if(this.connected !== true){
        this.connect();
      }
      this.currPath = to.path
      // console.log('currpath!!@!',this.currPath)
    },
  }
}
</script>

<style>
.v-list-item--active .v-list-item__content .appBar {
  color: #c97515 !important;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 10px;
}

.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 48px;
}

.v-list-item {
  min-height: 30px;
}

.v-toolbar__content {
  padding: 4px 0 !important;
}

.v-list-item__content {

}


</style>

<template>
  <div
      v-if="search.use"
  >
    <div
        v-if="search.usePlace === 'game'"
    >
      <div class="d-flex align-center justify-end">
        <div class="pr-2 d-flex align-center">

          <v-menu
              v-if="search.usedStartCalendar"
              v-model="startDateCalendars"
              :close-on-content-click="false"
              :nudge-left="50"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="startDate"
                  label=""
                  prepend-icon="mdi-calendar"
                  class="ma-0 pa-0"
                  readonly
                  style="max-width: 150px"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  clearable
                  dense
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="startDate"
                @input="startDateCalendars = false"
                locale="ko-KR"
                :day-format="krGetDay"
                :max=$endDate
            ></v-date-picker>
          </v-menu>
          <div
              v-if="search.usedStartCalendar && search.usedEndCalendar"
              class="px-2">
            -
          </div>
          <v-menu
              v-if="search.usedEndCalendar"
              v-model="endDateCalendars"
              :close-on-content-click="false"
              :nudge-left="50"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="endDate"
                  label=""
                  prepend-icon="mdi-calendar"
                  class="ma-0 pa-0"
                  readonly
                  style="max-width: 180px"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  clearable
                  dense
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="endDate"
                @input="endDateCalendars = false"
                locale="ko-KR"
                :day-format="krGetDay"
                :max=$endDate
            ></v-date-picker>
          </v-menu>

        </div>
        <v-switch
            v-model="showBetGame"
            @change="searchData()"
            class="ml-2 mr-2"
        >
          <template v-slot:label>
            <span style="font-size:12px">{{ showBetGame ? '베팅' : '전체' }}</span>
          </template>
        </v-switch>


        <v-select
            v-if="search.gameStatusList.length >0"
            v-model="gameStatus"
            :items="search.gameStatusList"
            item-text="text"
            item-value="value"
            label="상태"
            style="max-width: 130px"
            clearable
            outlined
            flat
            color="success"
            class="rounded-0 searchSelect font-11 mr-1"
            @change="searchData()"
            dense
            hide-details
        >
        </v-select>
        <v-select
            v-if="search.menuCodeList.length >0"
            v-model="menuCode"
            :items="search.menuCodeList"
            item-text="text"
            item-value="value"
            label="메뉴타입"
            style="max-width: 130px"
            clearable
            outlined
            flat
            color="success"
            class="rounded-0 searchSelect font-11 mr-1"
            dense
            hide-details
        >
        </v-select>
        <v-select
            v-if="search.gameCodeList.length >0"
            v-model="gameCode"
            :items="search.gameCodeList"
            item-text="text"
            item-value="value"
            label="종류"
            style="max-width: 130px"
            clearable
            outlined
            flat
            color="success"
            class="rounded-0 searchSelect font-11 mr-1"
            dense
            hide-details
        >
        </v-select>

        <v-select
            v-if="search.virtualGame"
            v-model="virtualLeague"
            :items="
            $route.params.menuCode === 'VSOCCER' ? search.soccerLeagueList :
            $route.params.menuCode === 'VBASKETBALL' ? search.basketballLeagueList :
            $route.params.menuCode === 'VDOGRACING' ? search.dogRacingLeagueList : ''"
            item-text="text"
            item-value="value"
            label="리그"
            style="max-width: 130px"
            clearable
            outlined
            flat
            color="success"
            class="rounded-0 searchSelect font-11 mr-1"
            dense
            hide-details
        >
        </v-select>

        <v-select
            v-if="search.usedSportsList"
            v-model="gameEvent"
            :items="eventList"
            item-text="name"
            item-value="name"
            label="종목"
            style="max-width: 130px"
            clearable
            outlined
            flat
            color="success"
            class="rounded-0 searchSelect font-11 mr-1"
            dense
            hide-details
        >
        </v-select>
        <v-select
            v-if="search.usedAgency"
            v-model="agencyId"
            :items="agencyList"
            item-text="name"
            item-value="agencyId"
            label="대리점"
            style="max-width: 130px"
            clearable
            outlined
            flat
            color="success"
            class="rounded-0 searchSelect font-11 mr-1"
            dense
            hide-details
        >
        </v-select>
        <v-select
            v-if="search.defaultSelectList.length >0"
            :items="search.defaultSelectList"
            item-text="text"
            item-value="value"
            v-model="searchCategory"
            style="max-width: 130px;"
            outlined
            clearable
            flat
            label="검색타입"
            color="success"
            class="rounded-0 searchSelect font-11"
            dense
            hide-details
        ></v-select>
        <v-select
            v-if="search.usedMemberStatus"
            v-model="statusValue"
            :items="search.memberStatusList"
            item-text="text"
            item-value="value"
            label="회원 상태"
            outlined
            flat
            clearable
            color="success"
            class="rounded-0 searchSelect font-11 px-1"
            style="max-width: 180px"
            dense
            hide-details
        >
        </v-select>

        <v-text-field
            v-if="search.defaultSelectList.length > 0"
            v-model="searchInput"
            clearable
            outlined
            hide-details
            class="rounded-0 searchInput font-11"
            placeholder="검색어를 입력해주세요."
            style="max-width: 220px;"
            @keyup.enter="searchData"
            dense
        ></v-text-field>
        <v-btn
            fab
            tile
            color="#4c4c4c"
            width="40"
            height="40"
            @click="searchData"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>

      </div>
    </div>
    <div
        v-else
    >
      <div v-if="!search.usedCalendar">
        <div class="d-flex align-center"
             :class="btnText !== '' ? 'justify-space-between': 'justify-end'"
        >

          <div class="d-flex">
            <v-btn
                v-if="btnText !== ''"
                tile
                small
                color="indigo"
                class="px-2"
                @click="goRegist"
            >
              {{ btnText }}
            </v-btn>
            <v-btn
                v-if="excel"
                tile
                small
                color="success"
                class="px-2"
                @click="downloadExcel"
            >
              엑셀 다운로드
            </v-btn>
          </div>


          <div class="d-flex align-center">
            <v-select
                v-if="search.usedAgency"
                v-model="agencyId"
                :items="agencyList"
                item-text="name"
                item-value="agencyId"
                label="대리점"
                style="max-width: 130px"
                clearable
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                dense
                hide-details
            >
            </v-select>
            <v-select
                v-if="search.usedMemberStatus"
                v-model="statusValue"
                :items="search.memberStatusList"
                item-text="text"
                item-value="value"
                label="회원 상태"
                outlined
                flat
                clearable
                color="success"
                class="rounded-0 searchSelect font-11 px-1"
                style="max-width: 180px"
                dense
                hide-details
            >
            </v-select>

            <v-select
                v-if="search.usedSportsList"
                v-model="gameEvent"
                :items="eventList"
                item-text="name"
                item-value="id"
                label="종목"
                style="max-width: 130px"
                clearable
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                dense
                hide-details
            >
            </v-select>

            <v-select
                v-if="search.defaultSelectList.length > 0 "
                :items="search.defaultSelectList"
                item-text="text"
                item-value="value"
                v-model="searchCategory"
                style="max-width: 130px"
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11"
                dense
                hide-details
            ></v-select>


            <v-text-field
                v-if="search.defaultSelectList.length > 0"
                v-model="searchInput"
                outlined
                hide-details
                class="rounded-0 searchInput font-11"
                placeholder="검색어를 입력해주세요."
                style="min-width: 220px;"
                @keyup.enter="searchData"
                dense
            ></v-text-field>
            <v-btn
                fab
                tile
                color="#4c4c4c"
                width="40"
                height="40"
                @click="searchData"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-center"
             :class="btnText !== '' || excel === true ? 'justify-space-between': 'justify-end'"
        >
          <div>
            <v-btn
                v-if="btnText !== ''"
                tile
                small
                color="indigo"
                class="px-2"
                @click="goRegist"
            >
              {{ btnText }}
            </v-btn>
            <v-btn
                v-if="excel"
                tile
                small
                color="success"
                class="px-2"


                @click="downloadExcel"
            >
              엑셀 다운로드
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-switch
                v-if="search.useAttentionMember"
                v-model="attentionMember"
                @change="searchData()"
                class="ml-2 mr-2"
            >
              <template v-slot:label>
                <span style="font-size:12px">관심회원</span>
              </template>
            </v-switch>
            <div class="pr-2 d-flex align-center">
              <v-switch
                  v-if="search.useAdminUpdated"
                  v-model="adminUpdated"
                  @change="searchData()"
                  class="ml-2 mr-2 mt-0"
                  hide-details
              >
                <template v-slot:label>
                  <span style="font-size:12px">{{ adminUpdated ? '관리자지급' : '전체' }}</span>
                </template>
              </v-switch>
              <v-menu
                  v-model="startDateCalendars"
                  :close-on-content-click="false"
                  :nudge-left="50"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="startDate"
                      label=""
                      prepend-icon="mdi-calendar"
                      class="ma-0 pa-0"
                      readonly
                      style="max-width: 150px"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      clearable
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="startDate"
                    @input="startDateCalendars = false"
                    locale="ko-KR"
                    :day-format="krGetDay"
                    :max=$endDate
                ></v-date-picker>
              </v-menu>
              <div class="px-2">
                -
              </div>
              <v-menu
                  v-model="endDateCalendars"
                  :close-on-content-click="false"
                  :nudge-left="50"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="endDate"
                      label=""
                      prepend-icon="mdi-calendar"
                      class="ma-0 pa-0"
                      readonly
                      style="max-width: 180px"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      clearable
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="endDate"
                    @input="endDateCalendars = false"
                    locale="ko-KR"
                    :day-format="krGetDay"
                    :max=$endDate
                ></v-date-picker>
              </v-menu>

            </div>

            <!--            <v-checkbox
                            v-if="search.sortBetAmount"
                            v-model="sortBetAmount"
                            @change="searchData()"
                            class="ml-2 mr-2"
                        >
                          <template v-slot:label>
                            <span style="font-size:12px" calss="mdi mdi-sort">베팅액순</span>
                          </template>
                        </v-checkbox>

                        <v-checkbox
                            v-if="search.sortHitAmount"
                            v-model="sortHitAmount"
                            @change="searchData()"
                            class="ml-2 mr-2"
                        >
                          <template v-slot:label>
                            <span style="font-size:12px" calss="mdi mdi-sort">당첨액순</span>
                          </template>
                        </v-checkbox>-->

            <v-select
                v-if="search.usedSortType"
                v-model="sortType"
                :items="search.sortTypeList"
                item-text="text"
                item-value="value"
                label="정렬"
                style="max-width: 130px"
                clearable
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                dense
                hide-details
            >
            </v-select>

            <v-select
                v-if="search.usedAgencyDepth"
                v-model="agencyDepth"
                :items="search.agencyDepthList"
                item-text="text"
                item-value="value"
                label="단계별"
                style="max-width: 130px"
                clearable
                outlined
                dense
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                hide-details
                @change="searchData()">
            </v-select>

            <v-select
                v-if="search.usedAgency"
                v-model="agencyId"
                :items="agencyList"
                item-text="name"
                item-value="agencyId"
                label="대리점"
                style="max-width: 130px"
                clearable
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                dense
                hide-details
            >
            </v-select>

            <v-select
                v-if="search.usedProcessStatus"
                v-model="processStatus"
                :items="search.processStatusList"
                item-text="text"
                item-value="value"
                label="상태"
                style="max-width: 130px"
                clearable
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                dense
                hide-details
            >
            </v-select>

            <v-select
                v-if="search.usedMenuCode"
                v-model="menuCode"
                :items="search.menuCodeList"
                item-text="text"
                item-value="value"
                label="메뉴별"
                style="max-width: 130px"
                clearable
                outlined
                flat
                color="success"
                class="rounded-0 searchSelect font-11 mr-1"
                dense
                hide-details
            >
            </v-select>

            <v-select
                v-if="search.usedBetStatus"
                v-model="betStatus"
                :items="search.betStatusList"
                item-text="text"
                item-value="value"
                label="베팅 상태"
                outlined
                flat
                clearable
                color="success"
                class="rounded-0 searchSelect font-11 px-1"
                style="max-width: 180px"
                hide-details
                dense
            >
            </v-select>

            <v-select
                v-if="search.usedMemberStatus"
                v-model="statusValue"
                :items="search.memberStatusList"
                item-text="text"
                item-value="value"
                label="회원 상태"
                outlined
                flat
                clearable
                color="success"
                class="rounded-0 searchSelect font-11 px-1"
                style="max-width: 180px"
                dense
                hide-details
            >
            </v-select>
            <v-select
                v-if="search.defaultSelectList.length > 0"
                :items="search.defaultSelectList"
                item-text="text"
                item-value="value"
                v-model="searchCategory"
                label="검색타입"
                style="max-width: 130px;"
                outlined
                flat
                dense
                color="success"
                class="rounded-0 searchSelect font-11"
                hide-details
            ></v-select>

            <v-text-field
                v-if="search.defaultSelectList.length > 0"
                v-model="searchInput"
                outlined
                hide-details
                dense
                class="rounded-0 searchInput font-11"
                placeholder="검색어를 입력해주세요."
                style="max-width: 220px;"
                @keyup.enter="searchData"

            ></v-text-field>
            <v-btn
                fab
                tile
                color="#4c4c4c"
                width="40"
                height="40"
                @click="searchData"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </div>


        </div>

      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: "SearchFormPc",
  data() {
    return {
      searchInput: '',
      virtualLeague: '',
      searchCategory: this.search.defaultSelectValue,
      useAttentionMember : this.search.useAttentionMember,
      statusValue: this.search.memberStatusValue,
      startDateCalendars: false,
      endDateCalendars: false,
      startDate: this.search.startDate,
      endDate: this.$endDate,
      agencyId: '',
      status: '',
      agencyList: [],
      eventList: [],
      transferTypeList: [],
      transferType:this.search.transferTypeValue,

      //게임
      gameStatus: this.search.gameStatusValue,
      gameCode: this.search.gameCodeValue,
      meunCode: this.search.menuCodeValue,
      gameEvent: this.search.eventList,
      showBetGame: false,
      attentionMember : false,
      //베팅내역
      betStatus: this.search.betStatusValue,
      sortType: null,
      //충환전
      processStatus: this.search.processStatusValue,
      //대리점별정산
      agencyDepth: this.search.agencyDepthValue,
      //메뉴코드별
      menuCode: this.search.menuCodeListValue,
      //로그어드민지급
      adminUpdated: this.search.adminUpdated,
      //어드민로그인로그
      adminOnly: this.search.adminOnly
    }
  },
  props: {
    btnText: {
      type: String
    },
    search: {
      type: Object
    },
    excel: {
      type: Boolean,
      default: false
    },

  },
  methods: {

    initSearchStatusUsingBetDialog: function () {
      this.startDate = this.search.startDate
      this.endDate = this.$endDate
      this.betStatus = this.search.betStatusValue
      this.menuCode = this.search.menuCodeListValue
      this.sortType = null
    },
    searchData: function () {
      let data = this.initSearchData()
      console.log('서치폼', data)
      this.$emit('searchData', data);
    },

    downloadExcel: function () {
      let data = this.initSearchData()
      this.$emit('downloadExcel',data)
    },

    initSearchData: function () {
      let data = {}
      let startDateChangeFormat = this.startDate
      let endDateChangeFormat = this.endDate

      if (startDateChangeFormat !== undefined) {
        startDateChangeFormat = this.startDate.replace(/-/g, '')
      }
      if (endDateChangeFormat !== undefined) {
        endDateChangeFormat = this.endDate.replace(/-/g, '')
      }
      if (this.searchInput === null) {
        this.searchInput = ''
      }
      let searchInput = this.searchInput.trim()
      if (this.search.usedCalendar) {
        if (this.monthDate !== undefined) {
          let yearMonth = this.monthDate.split('-')
          console.log(yearMonth)
          console.log(parseInt(yearMonth[1])-1)
          let lastDay = new Date(parseInt(yearMonth[0]), parseInt(yearMonth[1]), 0).getDate();
          let firstDay = new Date(parseInt(yearMonth[0]), parseInt(yearMonth[1])-1, 2).toISOString().slice(0, 10).replace(/-/g, '');
          console.log(firstDay)
          let lastYearMonthDay = yearMonth[0]+yearMonth[1]+lastDay
          console.log(lastYearMonthDay)
          data = {
            startDate: firstDay,
            endDate: lastYearMonthDay,
            category: this.searchCategory,
            data: searchInput
          }
        } else {
          data = {
            startDate: startDateChangeFormat,
            endDate: endDateChangeFormat,
            category: this.searchCategory,
            data: searchInput
          }
        }

      } else {
        data = {
          category: this.searchCategory,
          data: searchInput
        }
      }
      if (this.search.usedAgency) {
        data.agencyId = this.agencyId
      }
      if (this.defaultAgencyId !== '' && this.defaultAgencyId !== undefined) {
        data.agencyId = this.defaultAgencyId
      }
      if (this.search.usedMemberStatus) {
        data.memberStatus = this.statusValue
      }
      if (this.search.usedProcessStatus) {
        data.processStatus = this.processStatus
      }
      if (this.search.usedBetStatus) {
        data.betStatus = this.betStatus
      }
      if (this.search.usedSportsList) {
        data.sports = this.gameEvent
      }
      /*if (this.search.sortBetAmount) {
        data.sortBetAmount = this.sortBetAmount
      }
      if (this.search.sortHitAmount) {
        data.sortHitAmount = this.sortHitAmount
      }*/
      if (this.search.usedSortType) {
        data.sortType = this.sortType
      }
      if (this.search.usedAgencyDepth) {
        data.agencyDepth = this.agencyDepth
      }
      if (this.search.usePlace === 'game') {
        data.sports = this.gameEvent
        data.gameCode = this.gameCode
        data.menuCode = this.menuCode
        data.status = this.gameStatus
        data.matchedAt = this.startDate
        data.showBetGame = this.showBetGame
      }
      if (this.search.virtualGame) {
        data.league = this.virtualLeague
      }
      if (this.search.usedMenuCode) {
        data.menuCode = this.menuCode
      }
      if (this.search.useAdminUpdated) {
        data.adminUpdated = this.adminUpdated
      }

      if (this.search.useAttentionMember) {
        if(this.attentionMember){
          data.attentionMember = 'attention'
        }
        else{
          data.attentionMember = ''
        }
      }

      if (this.search.useAdminOnly) {
        data.adminOnly = this.adminOnly
      }

      if (this.search.useTransferType){
        data.transferType = this.transferType
      }

      return data
    },
    goRegist: function () {
      this.$emit('goRegist')
    },
    getAgencyList: async function () {
      this.agencyList = await this.$getAgencyData().then(function (data) {
        return data.data
      })
    },
    getSportsList: async function () {
      this.eventList = await this.$getSportsData().then(function (data) {
        return data
      })
    },

    krGetDay (date) {

      const day = date.split("-")[2];

      const day_num = Number(day);

      return day_num;

    },



  },
  mounted() {
    if (this.search.usedAgency) {
      this.getAgencyList();
    }
    if (this.search.usePlace === 'game' || this.search.usedSportsList) {
      this.getSportsList();
    }
  },

  watch: {

  }
}
</script>

<style scoped>

</style>

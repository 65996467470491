<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center" style="width:15%">
          회차
        </th>
        <th class="text-center" style="width:10%">
          게임 타입
        </th>
        <th class="text-center" style="width:20%">
          승(HOME)
        </th>
        <th class="text-center" style="width:10%">
          무(기준점)
        </th>
        <th class="text-center" style="width:20%">
          패(AWAY)
        </th>
        <th class="text-center">
          결과
        </th>
        <th class="text-center">
          상태
        </th>
        <th class="text-center">
          취소
        </th>
      </tr>
      </thead>
      <tbody>
      <!--당첨-->
        <tr
            v-for="(betItem,i) in myBetList"
            :key="i"
            class="game-info">

          <td class="text-center">
            <div
                class="ovm-ClassificationBarButton_Icon cil-ClassificationIconLarge cil-ClassificationIconLarge-1 "></div>
            {{ betItem.gameDate }} {{ betItem.round }} 회차
          </td>
          <td class="text-center">
            {{ betItem.gameCodeText }}
          </td>
          <td
              class="text-center "
              :class="betItem.whichBet === 'ODD' || betItem.whichBet === 'LARGE' || betItem.whichBet === 'OVER' || betItem.whichBet === 'ODD_OVER' || betItem.whichBet === 'EVEN_OVER' ? 'betSelected font-weight-bold': ''">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{
                  betItem.gameCode === 'P_ODD_EVEN' ? '파워볼 홀' :
                      (betItem.gameCode === 'P_OVER_UNDER' ? '파워볼 오버' :
                          (betItem.gameCode === 'BALL_SIZE' ? '일반볼 소' :
                              (betItem.gameCode === 'P_SUM_ODD_EVEN' ? '일반볼 합 홀' :
                                  (betItem.gameCode === 'P_SUM_OVER_UNDER' ? '일반볼 합 오버' : ''))))
                }}
                {{
                  betItem.gameCode === 'ODD_EVEN' ? '홀' :
                      (betItem.gameCode === 'OVER_UNDER' ? '오버' :
                          (betItem.gameCode === 'ODD_OVER_UNDER' ? '홀+오버' :
                              (betItem.gameCode === 'EVEN_OVER_UNDER' ? '짝+오버' :'')))
                }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsHome }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'MEDIUM' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex align-center justify-center"
                 v-if="betItem.gameCode === 'BALL_SIZE'"
            >
              일반볼 중 {{betItem.oddsDraw}}
            </div>
            <div v-else>VS</div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'EVEN' || betItem.whichBet === 'SMALL' || betItem.whichBet === 'UNDER' || betItem.whichBet === 'ODD_UNDER' || betItem.whichBet === 'EVEN_UNDER' ? 'betSelected font-weight-bold': ''">

            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                {{ betItem.oddsAway }}
              </div>
              <div class="d-flex align-center text-right">
                {{
                  betItem.gameCode === 'P_ODD_EVEN' ? '파워볼 짝' :
                      (betItem.gameCode === 'P_OVER_UNDER' ? '파워볼 언더' :
                          (betItem.gameCode === 'BALL_SIZE' ? '일반볼 대' :
                              (betItem.gameCode === 'P_SUM_ODD_EVEN' ? '일반볼 합 짝' :
                                  (betItem.gameCode === 'P_SUM_OVER_UNDER' ? '일반볼 합 언더' : ''))))
                }}
                {{
                  betItem.gameCode === 'ODD_EVEN' ? '짝' :
                      (betItem.gameCode === 'OVER_UNDER' ? '언더' :
                          (betItem.gameCode === 'ODD_OVER_UNDER' ? '홀+언더' :
                              (betItem.gameCode === 'EVEN_OVER_UNDER' ? '짝+언더' :'')))
                }}
              </div>
            </div>
          </td>
          <td class="text-center">
                <span>
                  {{ betItem.wonBet }}
                </span>
          </td>
          <td class="text-center">
                <span>
                  {{ betItem.statusValue }}
                </span>
          </td>
          <td class="text-center">
            <v-btn
                tile
                small
                color="error"
                @click="cancelBetItem(betItem.betItemId)"
                disabled
            >관리자취소
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Array
    },

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },

  }

}
</script>

<style scoped>
th, td {
  font-size: 14px !important;
}


.win > td {
  background-color: #f7f4f214;
  color: #FFFFFF;
}

.lose > td {
  background-color: rgba(245, 241, 238, 0.08);
  color: #8d7f7f;
}

button {
  margin-right: 5px;
}


tr:hover {
  background-color: transparent !important;

}



.dog {
  margin:2px;
  padding:5px;
  border:1px solid #6e6e6e;
}

.dog div:nth-child(2n+1){

}
.dog div:nth-child(2n){
  min-width:40px;
}
</style>

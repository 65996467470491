import Vue from 'vue'
// import axios from "axios";
import {eventBus} from "@/plugins/eventBus";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

let endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
let socket = null
let stomp = null
const methods = {
    initSocket: function () {
        socket = new SockJS(process.env.VUE_APP_WS_URL);
        stomp = Stomp.over(socket);
        return stomp
    },
    socketDisconnect: function () {
        stomp.disconnect();
        socket.close();

    },
    refreshToken: async function () {
        let result = {flag: true, msg: '', status: 200};
        await this.$store
            .dispatch("REFRESHTOKEN")
            .then(response => {
                console.log(response)
                result.flag = true;
                console.log("refresh ok")
                // this.getData();
            })
            .catch(ex => {
                console.log("refresh")
                console.log(ex)
                result.flag = false
                result.msg = ex.response.data.message
                result.status = ex.response.status
                // if (ex.response.status === 403) {
                //     this.$logout();
                // }
            });
        return result;
    },
    logout: function () {
        this.$store
            .dispatch("LOGOUT")
            .then(response => {
                console.log(response)
                if (this.$route.path !== '/login') {
                    this.$router.push({name: "login"})
                }

            })
            .catch(ex => {
                console.log("global logout")
                console.log(ex)
            });
    },
    addNumber: function (currVal, addVal) {
        currVal = Number(currVal) + Number(addVal)
        return currVal
    },
    getAgencyData: async function () {
        let isSuccess = {flag: false, code: 400, msg: '', data: []};
        await Vue.axios
            .get('/admin/agencies/all/code',
                {
                    params: {},
                })
            .then(response => {
                isSuccess.flag = true;
                isSuccess.data = response.data;
                isSuccess.code = response.status;
                console.log('response', response)
            })
            .catch(ex => {
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        return isSuccess
    },

    getSportsData: async function () {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            await Vue.axios
                .get('/app/common/sports/',
                    {
                        params: {},
                    })
                .then(({data}) => {
                    console.log(data)
                    resolve(data)
                })
                .catch(ex => {

                    reject(ex)
                })
        });

    },
    setArrayDataQuery: function (data) {
        let ArrayData = []
        let ArrayDataQuery = '';
        let tempArrayData = [];
        ArrayData = data.filter(v => v.selected);
        for (let i = 0; i < ArrayData.length; i++) {
            tempArrayData.push(ArrayData[i].id)
        }
        return tempArrayData.join(',')
    },
    goMemberDetail: function (memberId, role = 'ROLE_MEMBER') {
        // window.open(`http://localhost:8081/member/detail/${memberId}`, "", "width=1000,height=1000,left=200,top=200");
        let rolePath = ''
        if (role === 'ROLE_AGENCY') {
            rolePath = 'agency'
            let agencyId = ''
            Vue.axios
                .get(`/admin/agencies/owner/${memberId}`,
                    {
                        params: {},
                    })
                .then(({data}) => {
                    agencyId = data.agencyId

                    console.log('agencyId', data)

                    if (process.env.NODE_ENV === 'development') {
                        window.open('http://' + location.host + `/${rolePath}/detail/${agencyId}`, "", "width=1500,height=1000,left=200,top=200");
                    } else {
                        window.open('https://' + location.host + `/${rolePath}/detail/${agencyId}`, "", "width=1500,height=1000,left=200,top=200");
                    }
                })
                .catch(ex => {
                })

            /*let agencyId = ''
            if (memberId) {
                agencyId = memberId
            } else {
                agencyId = []
            }
            this.$router.push({name: 'agencyDetail', params: {id: agencyId}}
                , function () {
                });*/

        } else {
            rolePath = 'member'
            if (process.env.NODE_ENV === 'development') {
                window.open('http://' + location.host + `/${rolePath}/detail/${memberId}`, "", "width=1500,height=1000,left=200,top=200");
            } else {
                window.open('https://' + location.host + `/${rolePath}/detail/${memberId}`, "", "width=1500,height=1000,left=200,top=200");
            }
        }


    },


    goBetList: function (gameId, whichBet) {
        // window.open(`http://localhost:8081/member/detail/${memberId}`, "", "width=1000,height=1000,left=200,top=200");
        window.open('https://' + location.host + `/BetHistoryPop/${gameId}/${whichBet}`, "", "width=1500,height=1000,left=200,top=200");
    },

    windowClose: function () {
        window.close()
    },
    eventBusGetData: function () {
        return eventBus.$on('getData', () => {
            this.getData()
        })
    },
    evnetBusGetDataDestroy: function () {
        return eventBus.$off('getData')
    },
    getBetListByMember: async function (id, data) {
        console.log('here;', id, data)
        if (!data.menuCode) {
            data.menuCode = 'SPORTS_GAME'
        }

        let params = {
            size: 20,
            from: data.from,
            to: data.to,
            status: data.status,
            sortType: data.sortType,
            menuCode: data.menuCode
        }
        if (data.page !== undefined) {
            params.page = data.page - 1
        }
        let isSuccess = {flag: false, code: 400, msg: '', data: {}};
        await Vue.axios
            .get(`/admin/bet/histories/byMember/${id}`,
                {
                    params: params,
                })
            .then((response) => {
                if (response.status === 200) {
                    // 사용가능


                    isSuccess.flag = true;
                    for (let i = 0; i < response.data.length; i++) {
                        response.data[i].show = false;
                    }
                    isSuccess.data = response.data;
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = "게임을 불러오기 오류.";
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        return isSuccess
    },
    getMemo: async function (id) {
        let isSuccess = {flag: false, code: 400, msg: '', data: {}};
        await Vue.axios
            .get(`/admin/members/${id}/memo`,
                {
                    params: {},
                })
            .then((response) => {
                if (response.status === 200) {
                    // 사용가능
                    isSuccess.flag = true;
                    isSuccess.data = response.data;
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = "게임을 불러오기 오류.";
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        return isSuccess
    },
    getBetDetailData: async function (betList, id, menuCode) {
        let detailList = []
        console.log(betList, id, menuCode)
        let betListIndex = betList.findIndex(v => v.betId === id)
        betList[betListIndex].show = !betList[betListIndex].show
        console.log(id)
        let isSuccess = {flag: false, code: 400, msg: '', data: {betList: [], betDetailList: {}}};
        await Vue.axios
            .get(`/admin/bet/histories/${menuCode}/${id}/betItems`,
                {
                    params: {},
                })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    // 사용가능
                    isSuccess.flag = true;
                    //이미 값이 들어가 있으면
                    // if (this.betDetailList[id]) {
                    //
                    // } else {
                    //   this.betDetailList[id] = data;
                    // }
                    detailList = response.data;
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = "게임을 불러오기 오류.";
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        isSuccess.data.betList = betList
        isSuccess.data.betDetailList = detailList
        console.log("isSuccess", isSuccess)
        return isSuccess;
    },

    getInplayBetDetailData: async function (betList, id) {
        let detailList = []
        let betListIndex = betList.findIndex(v => v.betId === id)
        betList[betListIndex].show = !betList[betListIndex].show
        console.log(id)
        let isSuccess = {flag: false, code: 400, msg: '', data: {betList: [], betDetailList: {}}};
        await Vue.axios
            .get(`/admin/bet/histories/INPLAY/${id}/betItems`,
                {
                    params: {},
                })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    // 사용가능
                    isSuccess.flag = true;
                    //이미 값이 들어가 있으면
                    // if (this.betDetailList[id]) {
                    //
                    // } else {
                    //   this.betDetailList[id] = data;
                    // }
                    detailList = response.data;
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = "게임을 불러오기 오류.";
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        isSuccess.data.betList = betList
        isSuccess.data.betDetailList = detailList
        console.log("isSuccess", isSuccess)
        return isSuccess;
    },

    cancelBet: async function (data) {
        let isSuccess = {flag: false, code: 400, msg: '',};
        let url = '';

        console.log('cancelbet', data)

        if (data.description === 'betCancel') {
            url = `/admin/bet/histories/${data.id}/cancel`
            if (data.menuCode === 'INPLAY') {
                url = `/admin/game/inplay/bet/histories/${data.id}/cancel`
            }
        }
        //개별취소(스포츠만)
        else {
            url = `/admin/bet/sportsBets/betItems/${data.id}/cancel`
            if (data.menuCode === 'INPLAY') {
                url = `/admin/game/inplay/betItems/${data.id}/cancel`
            }
        }
        await Vue.axios
            .post(url,
                data, {
                    headers: {"Content-Type": `application/json`}
                })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    // 사용가능
                    isSuccess.flag = true;
                    isSuccess.msg = response.data.message;

                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = `${data.description}를 실패하였습니다.`;
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        return isSuccess
    },

    downloadExcel: async function (url, params, name) {
        console.log(url, params)
        let isSuccess = {flag: false, code: 400, msg: '',};
        await Vue.axios
            .post(url, params,
                {responseType: 'blob'},
            )
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', name + (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) + '.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    // 사용가능
                    isSuccess.flag = true;
                    isSuccess.msg = response.data.message;
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = `엑셀 다운로드를 실패하였습니다.`;
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        return isSuccess
    },
    pageDataSetting(total, limit, block, page) {
        let pageSetting = {}
        const totalPage = Math.ceil(total / limit)
        let currentPage = page
        const first =
            currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null
        const end =
            totalPage !== currentPage
                ? parseInt(currentPage, 10) + parseInt(1, 10)
                : null

        let prevGroup = parseInt(page % block ) === 0
            ? page - (page % block) - block
            : page - (page % block)
        if (prevGroup <= 0){
            prevGroup = 1
        }

        const nextGroup = parseInt(page % block ) === 0
            ? parseInt(page + 1)
            : page - (page % block) + block + 1

        let startIndex = (Math.ceil(currentPage / block) - 1) * block + 1
        let endIndex =
            startIndex + block > totalPage ? totalPage : startIndex + block - 1
        let list = []
        for (let index = startIndex; index <= endIndex; index++) {
            list.push(index)
        }
        return pageSetting = { first, end, list, currentPage, totalPage, block, prevGroup, nextGroup }

    },




}
export default {
    install(Vue) {
        Vue.prototype.$endDate = endDate
        Vue.prototype.$stomp = stomp

        Vue.prototype.$refreshToken = methods.refreshToken
        Vue.prototype.$logout = methods.logout
        Vue.prototype.$addNumber = methods.addNumber
        // Vue.prototype.$setNowTimes = methods.setNowTimes
        Vue.prototype.$getAgencyData = methods.getAgencyData
        Vue.prototype.$getSportsData = methods.getSportsData
        Vue.prototype.$setArrayDataQuery = methods.setArrayDataQuery
        Vue.prototype.$goMemberDetail = methods.goMemberDetail
        Vue.prototype.$goBetList = methods.goBetList
        Vue.prototype.$socketDisconnect = methods.socketDisconnect
        Vue.prototype.$initSocket = methods.initSocket
        Vue.prototype.$windowClose = methods.windowClose
        Vue.prototype.$eventBusGetData = methods.eventBusGetData
        Vue.prototype.$evnetBusGetDataDestroy = methods.evnetBusGetDataDestroy
        Vue.prototype.$getBetListByMember = methods.getBetListByMember
        Vue.prototype.$getBetDetailData = methods.getBetDetailData
        Vue.prototype.$getInplayBetDetailData = methods.getInplayBetDetailData
        Vue.prototype.$getMemo = methods.getMemo
        Vue.prototype.$cancelBet = methods.cancelBet
        Vue.prototype.$downloadExcel = methods.downloadExcel
        Vue.prototype.$pageDataSetting = methods.pageDataSetting
        Vue.prototype.$move = function (path) {
            if (this.$route.path !== path) {
                this.$router.push(path).catch(() => {
                });
            } else {
                this.$router.go(this.$router.currentRoute);
            }
        };
    }
}




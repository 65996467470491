<template>

  <div class="">
    <div v-if='this.$route.params.menuCode !== undefined'
         class="subTitle">베팅내역 ({{ this.$route.params.menuCode }})
    </div>
    <!--        검색바-->
    <div class="mb-5">
      <search-form-pc
          :search="search"
          v-on:goRegist="goRegist"
          v-on:searchData="searchData"
          btnText=""
          ref="searchForm"
      >
      </search-form-pc>
    </div>
    <div class="px-2">
      <v-chip-group
          active-class="primary--text"
          column
          mandatory
          v-model="menuCode"
          @change="setMenuCode"
      >
        <v-chip
            v-for="(item,i) in menuCodeList"
            :key="i"

        >
          {{ item }}
        </v-chip>
      </v-chip-group>
    </div>

    <!--        테이블-->
    <div>

      <v-simple-table
          class="dataList mb-1"
          dense
          v-if="menuCodeList === undefined"
      >
        <thead>
        <tr>
          <th class="text-center">베팅금</th>
          <th class="text-center">당첨금</th>
          <th class="text-center">베팅-당첨</th>
          <th class="text-center">롤링</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-center">{{ summary.betAmount | comma }}</td>
          <td class="text-center">{{ summary.winAmount | comma }}</td>
          <td class="text-center">{{ parseInt(summary.betAmount) - parseInt(summary.winAmount) | comma }}</td>
          <td class="text-center">{{ summary.commission | comma }}</td>
        </tr>
        </tbody>
      </v-simple-table>

      <v-simple-table
          class="dataList"
      >
<!--        <template v-slot:default>-->
          <thead>
          <tr>
            <th v-if="printNumber !== undefined"
                class="text-center">
              No.
            </th>
            <th class="text-center" v-if="$route.params.menuCode ==='MINI_GAME'">
              구분
            </th>
            <th class="text-center" style="width:10%">
              <div class="d-flex">
                <div>
                  ID
                </div>
                <div class="pl-2 font-orange">
                  (닉네임)
                </div>
              </div>
            </th>

            <th class="text-center" :style="$route.params.menuCode ==='SPORTS_GAME' ? 'width:40%' : 'width:20%'">
              진행내역
            </th>
            <th class="text-center">
              배당
            </th>
            <th class="text-center">
              베팅액
            </th>
            <th class="text-center" style="min-width:90px">
              예상금액
            </th>
            <th class="text-center" style="min-width:90px">
              적중금액
            </th>
            <th class="text-center" v-if="$route.name ==='inplayBetHistory'">
              상태
            </th>
            <th class="text-center" style="width:5%">
              IP
            </th>
            <th class="text-center">
              베팅시간
            </th>
            <th class="text-center">
              정산시간
            </th>
            <th class="text-center">
              관심
            </th>
            <th class="text-center">취소</th>

          </tr>
          </thead>

          <tbody
              v-for="(item,i) in betList"
              :key="i"
          >
          <tr @click="getBetDetailData(item.betId,item.menuCode)"
              :style="$route.name === 'betHistoryAttention' && item.attended === false? 'background-color: #3e3e3e':''"
          >
            <td
                v-if="printNumber !== undefined"
                class="text-center">
              {{ printNumber + i }}
            </td>
            <td class="text-center" v-if="$route.params.menuCode ==='MINI_GAME'">
              {{ item.menuCode }}
            </td>
            <td class="text-center">

              <div class="d-flex" @click.stop.prevent="goMemberDetail(item)">

                <div>
                  {{ item.memberBasicInfo.username }}
                </div>

                <div class="pl-2 font-orange">
                  ({{ item.memberBasicInfo.nickname }})
                </div>

                <div>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex" v-on="on">
                        <div class="ml-2" v-if="item.memberBasicInfo.hasMemo">
                          <v-icon
                              style="font-size:18px"
                              v-bind="attrs"
                              @mouseover="getMemo(item.memberBasicInfo.memberId)"
                          >mdi-message-alert-outline
                          </v-icon>
                        </div>
                      </div>
                    </template>
                    <span
                        v-if="item.memberBasicInfo.hasMemo"
                        v-html="memoContent.memo"
                        class="memoBox"
                    ></span>
                  </v-tooltip>
                </div>
              </div>
            </td>

            <td class="text-center">
            <span class="betItemStatus"
                  v-for="(betItem,j) in item.betItemsStatus"
                  :key="j"
            >
             <v-btn
                 outlined
                 tile
                 small
                 style="font-size:11px;"
                 :color="
                  betItem ==='베팅중' ? '#78909C' :
                  betItem ==='베팅종료' ? '#98C8DF' :
                  betItem ==='정산중' ? '#7986CB' :
                  betItem ==='미적중' ? '#448AFF' :
                  betItem ==='관리자취소' ? '#EEEEEE' :
                  betItem ==='적중' ? '#FF5252' : ''"
             >
                {{ betItem }}
              </v-btn>

            </span>
            </td>
            <td class="text-center">
              <span v-if="$route.params.menuCode !== 'CASINO'">{{
                  item.status === 'WON' ? item.winOdds : item.odds
                }} </span>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              {{ item.betAmount | makeComma }}
            </td>

            <td class="text-center">
              <span>{{ item.estimateAmount | makeComma }}</span>
            </td>

            <td class="text-center">
             <span >{{ item.winAmount | makeComma }}</span>
            </td>
            <td class="text-center" v-if="$route.name ==='inplayBetHistory'">
              <span v-if="item.confirmed">정상</span>
              <span v-else>승인대기</span>
            </td>
            <td class="text-center">
              {{ item.clientIp }}
            </td>
            <td class="text-center">
              {{ item.betAt }}
            </td>
            <td class="text-center">
              {{ item.calculationAt }}
            </td>
            <td class="text-center">
              <v-icon style="color:red"> {{ item.attended ? 'mdi-check-bold' : '' }}</v-icon>
            </td>
            <td class="text-center">
              <v-btn
                  tile
                  small
                  color="error"
                  @click.stop.prevent="cancelBet(item.betId)"
                  :disabled="!item.canCancel"
                  style="font-size:11px;"
              >
                취소
              </v-btn>
            </td>

          </tr>
          <tr
              class="px-1"
              :class="item.show ? '' : 'd-none'"
          >
            <td colspan="12"
                class="px-0">
              <bet-detail
                  class="py-1"
                  :ref="item.betId"
                  :menuCode="item.menuCode"
                  :betDetailList="betDetailList[item.betId]"
                  v-on:cancelBetItem="cancelBetItem"
                  v-on:openResetBetItem="openResetBetItem"
              >
              </bet-detail>
            </td>

          </tr>
          </tbody>
<!--        </template>-->

      </v-simple-table>
    </div>
  </div>
</template>

<script>
import BetDetail from "@/components/Sport/BetHistory/Detail/Pc";
import SearchFormPc from "@/components/_Default/SearchForm/Pc";

export default {
  name: "BoardPc",
  data() {
    return {
      startDateCalendars: false,
      endDateCalendars: false,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      menuCode: 0
    }
  },
  components: {
    BetDetail,
    SearchFormPc
  },

  props: {

    search: {
      type: Object
    },

    betList: {
      type: Array
    },
    betDetailList: {
      type: Object
    },
    printNumber: {
      type: Number
    },
    memoContent: {
      type: Object
    },
    menuCodeList: {
      type: Array
    },
    summary: {
      type: Object
    },
  },
  methods: {
    setMenuCode: function (item) {
      this.$refs.searchForm.searchData()
    },

    initSearchStatusUsingBetDialog: function () {
      this.$refs.searchForm.initSearchStatusUsingBetDialog()
    },
    searchData: function (data) {
      console.log(data)
      data.menuCode = this.menuCode
      this.$emit('searchData', data);
    },
    goRegist: function () {
      this.$emit('goRegist')
    },
    goMemberDetail: function (item) {
      this.$emit('goMemberDetail', item)
    },

    getBetDetailData: function (id, menuCode) {
      this.$emit('getBetDetailData', id, menuCode)
    },
    editData: function (item) {
      this.$emit('editData', item)
    },
    deleteData: function (id) {
      this.$emit('deleteData', id)
    },
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    openDetail: function (id) {
      this.$forceUpdate()
      this.$refs[id][0].openDetail(this.betDetailList[id])
    },
    getMemo: function (id) {
      this.$emit('getMemo', id)
    },
    openResetBetItem: function (id) {
      this.$emit('openResetBetItem', id)
    },


  }
}
</script>

<style scoped>


</style>
